import { Tenant, TenantPage, TenantSubscription } from '@/store/user-store';
import axios from 'axios';

async function getTenant(token: string): Promise<Tenant> {
  try {
    const response = await axios.get<Tenant>(`${process.env.VUE_APP_API_HOST}/tenant/me`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return null as any;
  }
}

async function getTenantPages(token: string): Promise<TenantPage[]> {
  try {
    const response = await axios.get<TenantPage[]>(`${process.env.VUE_APP_API_HOST}/page/`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return null as any;
  }
}

async function getTenantSubscriptions(token: string): Promise<TenantSubscription[]> {
  try {
    const response = await axios.get<TenantSubscription[]>(`${process.env.VUE_APP_API_HOST}/tenant/subscription`, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

async function tenantSubscribe(token: string): Promise<string> {
  try {
    const response = await axios.post<{ url: string }>(`${process.env.VUE_APP_API_HOST}/tenant/subscription`,
    null, {
      headers: { authorization: token }
    });

    return response.data.url;
  } catch (err) {
    throw err;
  }
}

async function tenantUnsubscribe(token: string): Promise<void> {
  try {
    await axios.delete(`${process.env.VUE_APP_API_HOST}/tenant/unsubscribe`, {
      headers: { authorization: token }
    });
  } catch (err) {
    throw err;
  }
}

async function linkStripeAccount(token: string): Promise<string> {
  try {
    const response = await axios.get<{ url: string }>(`${process.env.VUE_APP_API_HOST}/tenant/linkStripeAccount`, {
      headers: { authorization: token }
    });

    return response.data.url;
  } catch (err) {
    throw err;
  }
}

export {
  getTenant,
  getTenantPages,
  getTenantSubscriptions,
  tenantSubscribe,
  tenantUnsubscribe,
  linkStripeAccount,
};
