export function getCookie(name: string): any {
  let obj: any;
  if (localStorage.getItem(name)) {
    obj = JSON.parse(localStorage.getItem(name)!);
  }
  return obj;
}

export function setCookie(name: string, data: any): void {
  if (localStorage.getItem(name)) {
    let obj = JSON.parse(localStorage.getItem(name)!);
    obj = { obj, ...data };
    localStorage.setItem(name, JSON.stringify(obj || ''));
  } else {
    localStorage.setItem(name, JSON.stringify(data || ''));
  }
}

export function clearCookie(name: string): void {
  localStorage.removeItem(name);
}

export function copyToClipboard(content: string): void {
  navigator.clipboard.writeText(content);
}
