
import { ROUTE_NAME } from '@/router';
import Vue from 'vue';

export default Vue.extend({
  name: 'stripe-create-menu',
  methods: {
    // event
    onClick() {
      this.$router.push({
        name: ROUTE_NAME.FAQ_STRIPE_CREATE
      });
    }
  }
});
