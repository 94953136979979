
import { clearCookie } from '@/helper/browser';
import { TenantPage } from '@/store/user-store';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'page-selection-component',
  data() {
    return {
      pages: [],
    };
  },
  computed: {
    ...mapGetters('userStore', ['userStore']),
  },
  methods: {
    onPageClick(page: TenantPage) {
      if (page.id === this.userStore.selectedPage.id) {
        return;
      }
      this.$store.dispatch('userStore/selectPage', page);
      this.$store.dispatch('sessionStore/getSessionListAction');
      this.$store.dispatch('orderStore/resetOrderState');
      clearCookie('bb_usr_settings');
    },
  },
});
