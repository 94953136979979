
import Vue from 'vue';
import LoginComponent from '@/components/login/login-component.vue';

export default Vue.extend({
  name: 'home-view',
  components: {
    LoginComponent
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  created() {
    // this.$vuetify.theme.dark = false;
  }
});
