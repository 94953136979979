
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'confirm-dialog-component',
  data () {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('uiStore', ['confirmDialog'])
  },
  methods: {
    dialogClicked(ok: boolean) {
      if (ok) this.$store.dispatch('uiStore/confirmDialogOk', this.confirmDialog);
      else this.$store.dispatch('uiStore/confirmDialogCancel');
    },
  }
});
