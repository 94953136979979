
import Vue from 'vue';
import { ActionPayload, mapGetters } from 'vuex';
import copyTextTooltip from '@/components/shared/copy-text-tooltip/copy-text-tooltip.vue';

let unsubscribeAction: () => void;

export default Vue.extend({
  name: 'update-courier',
  components: {
    copyTextTooltip,
  },
  props: {
    cartId: { type: String, default: '' },
    courier: { type: String, default: '' },
    trackingNumber: { type: String, default: '' },
    courierEditorMode: { type: String, default: 'create' },
  },
  data: (): {
    courierModel: string,
    trackingNumberModel: string,
    editing: boolean;
  } => ({
    courierModel: '',
    trackingNumberModel: '',
    editing: false,
  }),
  computed: {
    updateMsg(): string {
      if (this.updatingCourier.loading && this.updatingCourier.cartId === this.cartId) {
        return this.$t('general_module.updating').toString();
      } else {
        return this.$t('order_module.order_table.update_now').toString();
      }
    },
    updating(): boolean {
      return this.updatingCourier.loading && this.updatingCourier.cartId === this.cartId;
    },
    ...mapGetters('orderStore', ['updatingCourier', 'listLoading']),
  },
  created() {
    unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
      switch (action.type) {
        case 'orderStore/UPDATE_COURIER_OK': {
          this.editing = false;
          break;
        }
        case 'uiStore/confirmDialogOk': {
          if (action.payload?.from && action.payload.from === `update-courier-${this.cartId}`) {
            this.editing = true;
            this.courierModel = this.courier;
            this.trackingNumberModel = this.trackingNumber;
          }
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeAction) unsubscribeAction();
  },
  methods: {
    // events
    onUpdateShipping() {
      if (!this.courierModel || !this.trackingNumberModel) {
        this.$store.dispatch('uiStore/setInfoDialogAction', {
          from: 'update-courier-alert',
          title: this.$t('general_module.dialog.title_warning'),
          text: this.$t('order_module.order_table.dialog.update_courier_all_fields_required_msg'),
          icon: 'mdi-alert-outline',
          color: 'warning'
        });
        return;
      }
      const result = {
        cartId: this.cartId,
        courier: this.courierModel,
        trackingNumber: this.trackingNumberModel
      };
      this.$emit('courierUpdated', result);
    },
    onEditClick() {
      this.$store.dispatch('uiStore/setConfirmDialog', {
        from: `update-courier-${this.cartId}`,
        title: this.$t('general_module.dialog.title_confirmation'),
        text: this.$t('order_module.order_table.dialog.update_courier_confirmation'),
      });
    }
  }
});
