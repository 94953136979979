import { deleteShippingTemplate, getShippingTemplate, getShippingTemplates, patchShippingTemplate, putShippingTemplate } from '@/services/setting-service';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import store, { RootState } from '.';

const namespaced = true;
const defaultSettingState = (): SettingState => {
  return {
    shipping: {
      list: [],
      listLoading: false,
      entity: null,
      entityLoading: false,
      saving: false,
      dialog: {
        show: false,
        mode: 'create'
      }
    }
  };
};

const state = defaultSettingState();

const getters: GetterTree<SettingState, RootState> = {
  shipping: (state) => state.shipping
};

const actions: ActionTree<SettingState, RootState> = {
  async getShippingTemplateList({ commit, dispatch }) {
    commit('loadingShippingTemplates', true);
    try {
      const data = await getShippingTemplates();
      if (data) {
        commit('setShippingTemplates', data);
      } else {
        dispatch('uiStore/setSnackbar', {
          color: 'error',
          text: 'Unable to retrieve shipping templates, please try again later.'
        }, { root: true });
      }
    } catch (err) {
      console.error(err);
      commit('setShippingTemplates', []);
      dispatch('uiStore/setSnackbar', {
        color: 'error',
        text: 'Unable to retrieve shipping templates, please try again later.'
      }, { root: true });
    } finally {
      commit('loadingShippingTemplates', false);
    }
  },
  async getShippingTemplate({ commit, dispatch }, params) {
    commit('loadingShippingTemplate', true);
    try {
      const data = await getShippingTemplate(params.id);

      if (data) {
        commit('setShippingTemplate', data);
      } else {
        dispatch('uiStore/setSnackbar', {
          color: 'error',
          text: 'Unable to retrieve selected shipping template, please try again later.'
        }, { root: true });
      }
    } catch (err) {
      console.error(err);
      commit('setShippingTemplate', undefined);
      dispatch('uiStore/setSnackbar', {
        color: 'error',
        text: 'Unable to retrieve selected shipping template, please try again later.'
      }, { root: true });
    } finally {
      commit('loadingShippingTemplate', false);
      store.dispatch('settingStore/setShippingTemplateDone');
    }
  },
  async createShippingTemplate({ commit, dispatch }, params) {
    commit('savingShippingTemplate', true);
    try {
      const data = await putShippingTemplate(params);
      if (data && data.id) {
        dispatch('uiStore/setSnackbar', {
          color: 'success',
          text: `Successfully created shipping template ${params.name}.`
        }, { root: true });
        store.dispatch('settingStore/getShippingTemplateList');
      } else {
        dispatch('uiStore/setSnackbar', {
          color: 'error',
          text: 'Unable to create shipping template, please try again later.'
        }, { root: true });
      }
    } catch (err) {
      console.error(err);
      dispatch('uiStore/setSnackbar', {
        color: 'error',
        text: 'Unable to create shipping template, please try again later.'
      }, { root: true });
    } finally {
      commit('savingShippingTemplate', false);
    }
  },
  async updateShippingTemplate({ commit, dispatch }, params: ShippingTemplate) {
    commit('savingShippingTemplate', true);
    try {

      const data = await patchShippingTemplate(params);
      if (data) {
        dispatch('uiStore/setSnackbar', {
          color: 'success',
          text: `Successfully updated shipping template ${params.name}.`
        }, { root: true });
        store.dispatch('settingStore/getShippingTemplateList');
      } else {
        dispatch('uiStore/setSnackbar', {
          color: 'error',
          text: 'Unable to update shipping template, please try again later.'
        }, { root: true });
      }
    } catch (err) {
      console.error(err);
      dispatch('uiStore/setSnackbar', {
        color: 'error',
        text: 'Unable to update shipping template, please try again later.'
      }, { root: true });
    } finally {
      commit('savingShippingTemplate', false);
    }
  },
  async deleteShippingTemplate({ commit, dispatch }, params: ShippingTemplate) {
    commit('savingShippingTemplate', true);
    try {

      const data = await deleteShippingTemplate(params.id);
      if (data) {
        dispatch('uiStore/setSnackbar', {
          color: 'success',
          text: `Successfully deleted shipping template ${params.name}.`
        }, { root: true });
        store.dispatch('settingStore/getShippingTemplateList');
      } else {
        dispatch('uiStore/setSnackbar', {
          color: 'error',
          text: `Unable to delete shipping template, please try again later ${params.name}.`
        }, { root: true });
      }
    } catch (err) {
      console.error(err);
      dispatch('uiStore/setSnackbar', {
        color: 'error',
        text: `Unable to delete shipping template, please try again later ${params.name}.`
      }, { root: true });
    } finally {
      commit('savingShippingTemplate', false);
    }
  },
  setShippingTemplateDone() {
    return;
  },
  setShippingTemplateDialog({ commit }, params) {
    if (params.show) commit('showDialog', params.mode);
    else commit('hideDialog', params.mode);
  },
  resetSettingState({ commit }) {
    commit('resetShippingTemplateDialog');
  }
};

const mutations: MutationTree<SettingState> = {
  savingShippingTemplate: (state, loading) => {
    state.shipping.saving = loading;
  },
  loadingShippingTemplates: (state, loading) => {
    state.shipping.listLoading = loading;
  },
  loadingShippingTemplate: (state, loading) => {
    state.shipping.entityLoading = loading;
  },
  setShippingTemplates: (state, data) => {
    state.shipping.list = data;
  },
  setShippingTemplate: (state, data) => {
    state.shipping.entity = data;
  },
  showDialog: (state, mode: 'create' | 'edit') => {
    state.shipping.dialog.show = true;
    state.shipping.dialog.mode = mode;
  },
  hideDialog: (state) => {
    state.shipping.dialog.show = false;
  },
  resetShippingTemplateDialog: (state) => {
    state.shipping.entity = defaultSettingState().shipping.entity;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export interface SettingState {
  shipping: Shipping;
}

export interface Shipping {
  list: ShippingTemplate[];
  listLoading: boolean;
  entity: ShippingTemplate | null;
  entityLoading: boolean;
  saving: boolean;
  dialog: {
    show: boolean;
    mode: 'create' | 'edit'
  };
}

export interface ShippingTemplate {
  id: string;
  name: string;
  regions: {
    name: string;
    fee: number;
  }[];
}
