import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginView from '../views/login/login-view.vue';
import HomeView from '../views/home/home-view.vue';
import DashboardView from '../views/dashboard/dashboard-view.vue';
import ProductView from '../views/dashboard/product-view.vue';
import OrderComponent from '../components/order/order-list.vue';

// general
import PageNotFoundComponent from '../components/home/page-not-found-component.vue';

// home components
import HomeComponent from '../components/home/home-component.vue';
import PricingComponent from '../components/home/pricing-component.vue';
import CopyrightComponent from '../components/home/copyright-component.vue';
import DisclaimerComponent from '../components/home/disclaimer-component.vue';
import PrivacyComponent from '../components/home/privacy-component.vue';
import TermsComponent from '../components/home/terms-component.vue';
import TermsOfUseComponent from '../components/home/terms-of-use-component.vue';
import ContactUsComponent from '../components/home/contact-us-component.vue';
import SuccessComponent from '../components/home/success-component.vue';
import SuccessLinkedComponent from '../components/home/success-linked-component.vue';
import SuccessPaymentComponent from '../components/home/success-payment-component.vue';
import CustomersComponent from '../components/home/customers-component.vue';
import PartnersComponent from '../components/home/partners-component.vue';
import JobsComponent from '../components/home/jobs-component.vue';
import BlogComponent from '../components/home/blogs-component.vue';

// dashboard
import NotFound from '../components/dashboard/notfound.vue';

// faq
import FaqView from '../views/dashboard/faq-view.vue';
import Faq from '../components/dashboard/faq.vue';
import BillerbeePermissionFaq from '../components/dashboard/faq/billerbee-permission/faq.vue';
import OrderReceiveFaq from '../components/dashboard/faq/order-receive/faq.vue';
import StripeCreateFaq from '../components/dashboard/faq/stripe-create/faq.vue';
import StripeLinkFaq from '../components/dashboard/faq/stripe-link/faq.vue';

// setting components
import SettingComponent from '../components/settings/setting.vue';
import SettingMenuComponent from '../components/settings/setting-menu.vue';
// import DashboardComponent from '../components/dashboard/dashboard-component.vue';
import ShippingTemplateComponent from '../components/settings/shipping-method/shipping-method-component.vue';
import SubscriptionComponent from '../components/settings/subscription/subscription.vue';
import StripePaymentComponent from '../components/settings/stripe-payment/stripe-payment.vue';

// auth
import { verifyToken } from '@/services/auth-service';

// cart
import CartView from '../views/cart/cart-view.vue';
import CartPayment from '../components/cart/cart-payment.vue';
import CartComponent from '../components/cart/cart-component.vue';
import CartSuccessPayment from '../components/cart/cart-success-payment.vue';

// sale
import SaleView from '../views/dashboard/sale-view.vue';
import SaleList from '../components/sale/sale-list.vue';
import SaleDetail from '../components/sale/sale-detail.vue';

Vue.use(VueRouter);

async function authentication(to: any, from: any, next: any) {
  try {
    const item = localStorage.getItem('bb_auths');

    if (!item) next('/dashboard/login');
    const tokens = JSON.parse(item as string);

    if (!tokens.access_token) next('/dashboard/login');
    const response = await verifyToken(tokens.access_token);

    if (response.status === 200) next();
    else {
      next('/dashboard/login');
      localStorage.setItem('bb_auths', '');
    }
  } catch (err) {
    next('/dashboard/login');
    localStorage.setItem('bb_auths', '');
  }
}

export const enum ROUTE_NAME {
  LOGIN = 'LOGIN',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_HOME = 'DASHBOARD_HOME',
  DASHBOARD_STRIPE_LINKED = 'DASHBOARD_STRIPE_LINKED',
  ORDERS = 'ORDERS',
  SALES = 'SALES',
  SALE_LIST = 'SALE_LIST',
  SALE_CREATE = 'SALE_CREATE',
  SALE_EDIT = 'SALE_EDIT',
  SETTINGS = 'SETTINGS',
  SETTINGS_MAIN = 'SETTINGS_MAIN',
  SETTINGS_SHIPPING_TEMPLATES = 'SETTINGS_SHIPPING_TEMPLATES',
  SETTINGS_SUBSCRIPTION = 'SETTINGS_SUBSCRIPTION',
  SETTINGS_STRIPE_PAYMENT = 'SETTINGS_STRIPE_PAYMENT',
  FAQ = 'FAQ',
  FAQ_MAIN = 'FAQ_MAIN',
  FAQ_BILLERBEE_PERMISSION = 'FAQ_BILLERBEE_PERMISSION',
  FAQ_ORDER_RECEIVE = 'FAQ_ORDER_RECEIVE',
  FAQ_STRIPE_CREATE = 'FAQ_STRIPE_CREATE',
  FAQ_STRIPE_LINK = 'FAQ_STRIPE_CREATE',
  CART = 'CART',
  CART_PAYMENT = 'CART_PAYMENT',
  CART_COMPONENT = 'CART_COMPONENT',
  CART_PAYMENT_SUCCESS = 'CART_PAYMENT_SUCCESS'
}

const routes: RouteConfig[] = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '/',
        component: HomeComponent
      },
      {
        path: 'home/pricing',
        component: PricingComponent
      },
      {
        path: 'home/copyright',
        component: CopyrightComponent
      },
      {
        path: 'home/disclaimer',
        component: DisclaimerComponent
      },
      {
        path: 'home/privacy',
        component: PrivacyComponent
      },
      {
        path: 'home/terms',
        component: TermsComponent
      },
      {
        path: 'home/termsOfUse',
        component: TermsOfUseComponent
      },
      {
        path: 'home/contact',
        component: ContactUsComponent
      },
      {
        path: 'home/success',
        component: SuccessComponent
      },
      {
        path: 'home/successLinked',
        redirect: {
          name: ROUTE_NAME.DASHBOARD,
          query: { sl: '1' } // sl: success linked
        },
        component: SuccessLinkedComponent
      },
      {
        path: 'home/successPayment',
        component: SuccessPaymentComponent
      },
      {
        path: 'customers',
        component: CustomersComponent
      },
      {
        path: 'partners',
        component: PartnersComponent
      },
      {
        path: 'jobs',
        component: JobsComponent
      },
      {
        path: 'blogs',
        component: BlogComponent
      },
      {
        path: 'home/notfound',
        component: PageNotFoundComponent
      }
    ]
  },
  {
    path: '/dashboard/login',
    name: ROUTE_NAME.LOGIN,
    component: LoginView
  },
  {
    path: '/dashboard',
    name: ROUTE_NAME.DASHBOARD,
    component: DashboardView,
    children: [
      {
        path: 'home',
        name: ROUTE_NAME.DASHBOARD_HOME,
        redirect: {
          name: ROUTE_NAME.SALE_LIST,
        },
        beforeEnter: authentication,
      },
      {
        path: 'orders',
        name: ROUTE_NAME.ORDERS,
        beforeEnter: authentication,
        component: OrderComponent
      },
      {
        path: 'sales',
        component: SaleView,
        children: [{
          path: '',
          name: ROUTE_NAME.SALE_LIST,
          component: SaleList,
          beforeEnter: authentication,
        },
        {
          path: 'create',
          name: ROUTE_NAME.SALE_CREATE,
          component: SaleDetail,
          beforeEnter: authentication,
        },
        {
          path: ':id',
          name: ROUTE_NAME.SALE_EDIT,
          component: SaleDetail,
          beforeEnter: authentication,
        }]
      },
      {
        path: 'products',
        name: 'products',
        component: ProductView
      },
      {
        path: 'settings',
        component: SettingComponent,
        children: [
          {
            path: '',
            name: ROUTE_NAME.SETTINGS_MAIN,
            beforeEnter: authentication,
            component: SettingMenuComponent,
          },
          {
            path: 'shipping-templates',
            name: ROUTE_NAME.SETTINGS_SHIPPING_TEMPLATES,
            beforeEnter: authentication,
            component: ShippingTemplateComponent,
          },
          {
            path: 'subscription',
            name: ROUTE_NAME.SETTINGS_SUBSCRIPTION,
            beforeEnter: authentication,
            component: SubscriptionComponent,
          },
          {
            path: 'stripe-payment',
            name: ROUTE_NAME.SETTINGS_STRIPE_PAYMENT,
            beforeEnter: authentication,
            component: StripePaymentComponent,
          },
        ]
      },
      {
        path: 'faq',
        component: FaqView,
        children: [
          {
            path: '',
            name: ROUTE_NAME.FAQ_MAIN,
            beforeEnter: authentication,
            component: Faq
          },
          {
            path: 'billerbee-permission',
            name: ROUTE_NAME.FAQ_BILLERBEE_PERMISSION,
            beforeEnter: authentication,
            component: BillerbeePermissionFaq
          },
          {
            path: 'order-receive',
            name: ROUTE_NAME.FAQ_ORDER_RECEIVE,
            beforeEnter: authentication,
            component: OrderReceiveFaq
          },
          {
            path: 'stripe-create',
            name: ROUTE_NAME.FAQ_STRIPE_CREATE,
            beforeEnter: authentication,
            component: StripeCreateFaq
          },
          {
            path: 'stripe-link',
            name: ROUTE_NAME.FAQ_STRIPE_LINK,
            beforeEnter: authentication,
            component: StripeLinkFaq
          },
        ]
      },
      {
        path: '*',
        component: NotFound
      },
    ]
  },
  {
    path: '/cart',
    name: ROUTE_NAME.CART,
    component: CartView,
    children: [
      {
        path: 'payment',
        name: ROUTE_NAME.CART_PAYMENT,
        component: CartPayment
      },
      {
        path: 'payment-success',
        name: ROUTE_NAME.CART_PAYMENT_SUCCESS,
        component: CartSuccessPayment
      },
      {
        path: ':cartId',
        name: ROUTE_NAME.CART_COMPONENT,
        component: CartComponent
      },
    ]
  },
  {
    path: '*',
    redirect: '/home/notfound'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
  }
});

export default router;
