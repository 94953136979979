
import Vue from 'vue';
export default Vue.extend({
  name: 'sale-detail-product',
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false
    };
  },
});
