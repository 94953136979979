
import Vue from 'vue';
import cardTitleComponent from '@/components/shared/card/card-title-component.vue';
import billerbeePermissionMenu from '@/components/dashboard/faq/billerbee-permission/menu.vue';
import orderReceiveMenu from '@/components/dashboard/faq/order-receive/menu.vue';
import stripeCreateMenu from '@/components/dashboard/faq/stripe-create/menu.vue';
import stripeLinkMenu from '@/components/dashboard/faq/stripe-link/menu.vue';

export default Vue.extend({
  name: 'faq',
  components: {
    cardTitleComponent,
    billerbeePermissionMenu,
    orderReceiveMenu,
    stripeCreateMenu,
    stripeLinkMenu,
  }
});
