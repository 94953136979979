const state = {
  sampleStore: {
    page: {},
  }
};

const getters = {
  sampleStore: (state: any) => state.sampleStore
};

const actions = {
  setPage({ commit }: any, params: any): void {
    commit('updatePage', params.page);
  }
};

const mutations = {
  updatePage: (state: any, page: any) => {
    state.sampleStore.page = page;
  }
};
export default {
  state, getters, actions, mutations
};
