
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'info-dialog',
  data () {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('uiStore', ['infoDialog'])
  },
  methods: {
    dialogClicked() {
      this.$store.dispatch('uiStore/infoDialogCloseAction');
    },
  }
});
