
import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'snackbar-component',
  computed: {
    ...mapGetters('uiStore', ['snackbar'])
  },
  // watch: {
  //   snackbar (newVal, oldVal) {
  //     console.log(newVal);
  //     console.log(oldVal);
  //   }
  // },
  methods: {
    onInput() {
      // console.log('shift' + i);
      this.snackbar.shift();
    }
  }
});
