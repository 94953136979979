
import { ROUTE_NAME } from '@/router';
import Vue from 'vue';

export default Vue.extend({
  name: 'billerbee-permission-menu',
  methods: {
    // event
    onClick() {
      this.$router.push({
        name: ROUTE_NAME.FAQ_BILLERBEE_PERMISSION
      });
    }
  }
});
