
import { ShippingTemplate } from '@/store/setting-store';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ShippingMethodDialogComponent from './shipping-method-dialog-component.vue';

let unsubscribe: any;

export default Vue.extend({
  name: 'shipping-method-component',
  components: {
    ShippingMethodDialogComponent,
  },
  data: () => ({
    deleteItem: null as any,
    shipPrice: 0,
    search: '',
    headers: [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Actions', value: 'actions', width: '15%', sortable: false, align: 'right' },
    ]
  }),
  computed: {
    ...mapGetters('settingStore', ['shipping']),
    ...mapGetters('uiStore', ['confirmDialog']),
  },
  created() {
    this.$store.dispatch('settingStore/getShippingTemplateList');
    unsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === 'uiStore/confirmDialogOk' && this.confirmDialog.from === this.$options.name) {
        this.$store.dispatch('settingStore/deleteShippingTemplate', this.deleteItem);
      }
    });
  },
  beforeDestroy() {
    if (unsubscribe) unsubscribe();
  },
  methods: {
    shipPriceInput(val: string) {
      if (+val < 0) this.shipPrice = 0;
    },
    onDelete(item: ShippingTemplate) {
      this.deleteItem = item;
      this.$store.dispatch('uiStore/setConfirmDialog', {
        from: this.$options.name,
        title: this.$t('settings_module.shipping_template.messages.delete_shipping_template_title'),
        text: this.$t('settings_module.shipping_template.messages.delete_shipping_template_msg'),
      });
    },
    onEdit(item: ShippingTemplate) {
      this.$store.dispatch('settingStore/getShippingTemplate', {
        id: item.id
      });
      this.$store.dispatch('settingStore/setShippingTemplateDialog', {
        show: true,
        mode: 'edit'
      });
    }
  },
});
