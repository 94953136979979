
import Vue from 'vue';

export default Vue.extend({
  name: 'home-footer-view',
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  methods: {
    scrollTo(id: string) {
      const element = document.getElementById(id) as HTMLElement;
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  }
});
