
import { copyToClipboard } from '@/helper/browser';
import { ROUTE_NAME } from '@/router';
import { Session } from '@/store/session-store';
import { UserState } from '@/store/user-store';
import Vue from 'vue';
import { ActionPayload, mapGetters, MutationPayload } from 'vuex';

let unsubscribeAction: () => void;
let unsubscribeMutation: () => void;

export default Vue.extend({
  data: () => ({
    timer: 0 as any,
    search: '',
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Name', align: 'start', value: 'name', sortable: false },
      { text: 'Currency', value: 'currency', sortable: false },
      { text: 'Expires on', value: 'expiredAt', sortable: false },
      { text: 'FB post URL', value: 'postURL', sortable: false },
      { text: 'Shipping template', value: 'shippingTemplate', sortable: false },
      { text: 'Actions', align: 'right', value: 'actions', sortable: false },
    ],
    statusSelect: 'PAID',
    selects: ['PAID', 'PENDING_PAYMENT', 'SHIPPED', 'COMPLETED', 'ALL'],
    pagination: {
      size: 25,
      page: 1
    },
    toDelete: null as any,
    activationId: ''
  }),
  computed: {
    pageDisabled(): boolean {
      if (!(this.userStore as UserState).selectedPage?.haveFacebookAccess) {
        return true;
      }
      return false;
    },
    paymentDisabled(): boolean {
      if (!(this.userStore as UserState).tenant.stripeAccountPaymentEnabled) {
        return true;
      }
      return false;
    },
    paymentProcessing(): boolean {
      if ((this.userStore as UserState).tenant.stripeAccount && !(this.userStore as UserState).tenant.stripeAccountPaymentEnabled) {
        return true;
      }
      return false;
    },
    ...mapGetters('sessionStore', ['session']),
    ...mapGetters('authStore', ['token']),
    ...mapGetters('uiStore', ['confirmDialog']),
    ...mapGetters('userStore', ['userStore']),
  },
  created () {
    if (this.userStore?.selectedPage?.id) {
      this.$store.dispatch('sessionStore/getSessionListAction');
    }

    unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
      if (action.type === 'uiStore/confirmDialogOk' && this.confirmDialog.from === 'session-delete') {
        this.$store.dispatch('sessionStore/deleteSessionAction', {
          id: this.toDelete.id
        });
      } else if (action.type === 'uiStore/confirmDialogOk' && this.confirmDialog.from === 'session-activate') {
        this.$store.dispatch('sessionStore/activateSessionAction', {
          sessionId: this.activationId
        });
      } else if (action.type === 'uiStore/confirmDialogOk' && this.confirmDialog.from === 'session-deactivate') {
        this.$store.dispatch('sessionStore/deactivateSessionAction', {
          sessionId: this.activationId
        });
      } else if (action.type === 'sessionStore/ACTIVATE_SESSION_OK') {
        this.$store.dispatch('sessionStore/getSessionListAction');
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'success',
          action: 'ACTIVATE_SESSION_OK',
          text: this.$t('session_module.session_table.messages.session_activated')
        });
      } else if (action.type === 'sessionStore/ACTIVATE_SESSION_FAILED') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'ACTIVATE_SESSION_FAILED',
          text: this.$t('session_module.session_table.errors.unable_activate_session')
        });
      } else if (action.type === 'sessionStore/DEACTIVATE_SESSION_OK') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'success',
          action: 'DEACTIVATE_SESSION_OK',
          text: this.$t('session_module.session_table.messages.session_deactivated')
        });
      } else if (action.type === 'sessionStore/DEACTIVATE_SESSION_FAILED') {
        const apiErrMsg = action.payload.apiErrCode ? this.$t(`backend_api_module.error_code.${action.payload.apiErrCode}`) : '';
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'DEACTIVATE_SESSION_FAILED',
          text: this.$t('session_module.session_table.errors.unable_deactivate_session', {
            0: apiErrMsg
          })
        });
      } else if (action.type === 'sessionStore/DELETE_SESSION_OK') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'success',
          action: 'DELETE_SESSION_OK',
          text: this.$t('session_module.session_table.messages.delete_session_ok')
        });
      } else if (action.type === 'sessionStore/DELETE_SESSION_FAILED') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'DELETE_SESSION_FAILED',
          text: this.$t('session_module.session_table.messages.delete_session_failed')
        });
      }
    });

    unsubscribeMutation = this.$store.subscribe((mutation: MutationPayload) => {
      switch (mutation.type) {
        case 'userStore/setSelectedPage': {
          this.$store.dispatch('sessionStore/getSessionListAction');
          break;
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeAction) unsubscribeAction();
    if (unsubscribeMutation) unsubscribeMutation();
  },
  methods: {
    pageChanged(page: any) {
      page = page || undefined;
      this.$router.replace({ query: {...this.$route.query, page }});
    },
    sizeChanged(size: any) {
      size = size || undefined;
      this.$router.replace({ query: {...this.$route.query, size }});
    },
    statusChanged() {
      const element  = this.$refs.statusSelect as HTMLElement;
      element.blur();
    },
    sessionChanged() {
      const element  = this.$refs.sessionSelect as HTMLElement;
      element.blur();
    },
    searchChanged(value: any) {
      value = value || undefined;
      if (this.timer) {
        clearTimeout(this.timer as any);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.$router.replace({ query: {...this.$route.query, search: value }});
      }, 800);
    },
    onEdit() {
      // console.log('edit clicked', item);
    },
    onDelete(item: Session) {
      this.toDelete = item;
      this.$store.dispatch('uiStore/setConfirmDialog', {
        from: 'session-delete',
        title: this.$t('session_module.session_table.messages.delete_session_title'),
        text: this.$t('session_module.session_table.messages.delete_session_msg')
      });
    },
    onViewOrders(item: Session) {
      this.$router.push({
        name: ROUTE_NAME.ORDERS,
        query: {
          s: item.id
        }
      });
    },
    onOpenInNew(item: Session) {
      window.open(item.postURL, '_blank');
    },
    onCopy(item: Session) {
      copyToClipboard(item.postURL);
      this.$store.dispatch('uiStore/setSnackbar', {
        color: 'info',
        action: 'item_copied',
        text: this.$t('session_module.session_table.messages.url_copied')
      });
    },
    onAdd() {
      this.$router.push({
        name: ROUTE_NAME.SALE_CREATE
      });
    },
    onView(item: Session) {
      this.$router.push({
        name: ROUTE_NAME.SALE_EDIT,
        params: { id: item.id }
      });
    },
    onToggle(sessionId: string, activated: boolean) {
      if (!sessionId) {
        alert(this.$t('session_module.session_table.errors.session_not_found'));
        return;
      }
      this.activationId = sessionId;
      if (activated) {
        this.$store.dispatch('uiStore/setConfirmDialog', {
          from: 'session-deactivate',
          title: this.$t('session_module.session_table.messages.deactivate_session_title'),
          text: this.$t('session_module.session_table.messages.deactivate_session_msg')
        });
      } else {
        this.$store.dispatch('uiStore/setConfirmDialog', {
          from: 'session-activate',
          title: this.$t('session_module.session_table.messages.activate_session_title'),
          text: this.$t('session_module.session_table.messages.activate_session_msg')
        });
      }
    }
  },
});
