import { RootState } from '.';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ApplicationSettings, getApplicationSettings } from '@/services/general-service';

const namespaced = true;
const defaultGeneralState = (): GeneralState => ({
  appSettings: {
    currencyPaymentMethod: [],
    minimumCheckoutAmount: 0
  }
});

const state = defaultGeneralState();

const getters: GetterTree<GeneralState, RootState> = {
  appSettings: (state) => state.appSettings,
};

const actions: ActionTree<GeneralState, RootState> = {
  async getApplicationSettingsAction({ dispatch }) {
    try {
      const data = await getApplicationSettings();
      if (data) {
        dispatch('GET_CURRENCY_PAYMENT_METHOD_OK', data);
      } else {
        throw new Error('API failure: unable to get currency payment methods');
      }
    } catch (err) {
      console.error(err);
      dispatch('GET_CURRENCY_PAYMENT_METHOD_FAILED');
    }
  },

  GET_CURRENCY_PAYMENT_METHOD_OK({ commit }, params: ApplicationSettings) {
    // GET_CURRENCY_PAYMENT_METHOD_OK
    commit('setCurrencyPaymentMethod', params);
  },

  GET_CURRENCY_PAYMENT_METHOD_FAILED() {
    // GET_CURRENCY_PAYMENT_METHOD_FAILED
  }
};

const mutations: MutationTree<GeneralState> = {
  setCurrencyPaymentMethod: (state, data: ApplicationSettings) => {
    const dto: CurrencyPaymentMethodDto[] = [];
    Object.entries(data.currencyPaymentMethods).forEach(([currency, method]) => {
      dto.push({
        currency: currency.toUpperCase(),
        methods: method
      });
    });
    state.appSettings.currencyPaymentMethod = dto;
  }
};

export interface GeneralState {
  appSettings: ApplicationSettingsDto;
}

export interface ApplicationSettingsDto {
  currencyPaymentMethod: CurrencyPaymentMethodDto[];
  minimumCheckoutAmount: number;
}

export interface CurrencyPaymentMethodDto {
  currency: string;
  methods: string[];
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};
