import { RootState } from '.';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { getCart, getIsPaidCart, patchCart, putCartItems } from '@/services/cart-service';
import { Session, ShippingFee } from './session-store';
import { getSession } from '@/services/session-service';

const namespaced = true;
const defaultCartState = (): CartState => {
  return {
    cart: {
      id: '',
      sessionId: '',
      customerId: '',
      customerName: '',
      paid: false,
      paidAt: '',
      items: undefined,
      mappedCartItems: [],
      deliverTo: {
        name: '',
        contactNo: '',
        fullAddress: '',
        address: {
          address: '',
          postcode: undefined,
          city: '',
          state: '',
          country: ''
        }
      },
      shippingFeeId: '',
      shippingFee: undefined,
      total: 0
    },
    cartLoading: false,
    cartItemsLoading: false,
    cartDeliveryLoading: false,
    cartShippingLoading: false,
    shippingAddressLoading: false,
    shippingTemplateLoading: false,
    session: undefined,
  };
};
const state = defaultCartState();

const getters: GetterTree<CartState, RootState> = {
  cartStore: (state): CartState => state,
  cart: (state) => state.cart,
  cartLoading: (state) => state.cartLoading,
  cartItemsLoading: (state) => state.cartItemsLoading,
  cartDeliveryLoading: (state) => state.cartDeliveryLoading,
  cartShippingLoading: (state) => state.cartShippingLoading,
  session: (state) => state.session,
};

const actions: ActionTree<CartState, RootState> = {
  async getCartAction({ commit, dispatch, rootState }, params: { cartId: string }) {
    commit('cartLoading', true);
    try {
      const cart = await getCart(params.cartId);
      const session = await getSession(cart.sessionId, rootState.authStore.token);
      if (cart && session) dispatch('GET_CART_OK', { cart, session });
      else throw new Error('API failure: Cart or session not found');
    } catch (err) {
      console.error(err);
      dispatch('GET_CART_FAILED');
    } finally {
      commit('cartLoading', false);
    }
  },

  GET_CART_OK({ commit }, params: { cart: Cart, session: Session}) {
    const mappedCartItems: MappedCartItem[] = [];

    params.cart.items?.forEach((item) => {
      const sessionItem = params.session.items.find((x) => item.itemId === x.id);
      if (!sessionItem) return;
      mappedCartItems.push({
        code: sessionItem.code,
        name: sessionItem.name,
        quantity: item.quantity,
        price: item.price,
        itemId: item.itemId
      });
    });
    params.cart.mappedCartItems = mappedCartItems;

    commit('setCartAndSession', params);
  },

  GET_CART_FAILED() {
    // get cart failed
  },

  async updateCartAction({ commit, dispatch }, params: { cartId: string, shippingFeeId: string, delivery: DeliveryDetail }) {
    commit('cartDeliveryLoading', true);
    commit('cartShippingLoading', true);
    try {
      await patchCart(params.cartId, params.shippingFeeId, params.delivery);
      dispatch('UPDATE_CART_OK');
    } catch (err) {
      console.error(err);
      dispatch('UPDATE_CART_FAILED');
    } finally {
      commit('cartDeliveryLoading', false);
      commit('cartShippingLoading', false);
    }
  },

  UPDATE_CART_OK() {
    // update cart success
  },

  UPDATE_CART_FAILED() {
    // update cart failed
  },

  async updateCartItemsAction({ commit, dispatch }, params: { cartId: string, items: CartItem[] }) {
    commit('cartItemsLoading', true);
    try {
      await putCartItems(params.cartId, params.items);
      dispatch('UPDATE_CART_ITEM_OK');
    } catch (err) {
      console.error(err);
      dispatch('UPDATE_CART_ITEM_FAILED');
    } finally {
      commit('cartItemsLoading', false);
    }
  },

  UPDATE_CART_ITEM_OK() {
    // update cart item success
  },

  UPDATE_CART_ITEM_FAILED() {
    // update cart item failed
  },

  async checkCartPaidAction({ dispatch }, params: { cartId: string }) {
    try {
      const paid = await getIsPaidCart(params.cartId);
      if (paid) dispatch('CHECK_CART_PAID_OK', { paid: true });
      else dispatch('CHECK_CART_PAID_OK', { paid: false });
    } catch (err: any) {
      if (err?.response?.status === 404) {
        dispatch('CHECK_CART_PAID_FAILED');
      }
    }
  },

  CHECK_CART_PAID_OK() {
    // check cart paid ok
  },

  CHECK_CART_PAID_FAILED() {
    // check cart paid failed
  },

  resetCartState({ commit }) {
    commit('resetCartState');
  }
};

const mutations: MutationTree<CartState> = {
  resetCartState: (state) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state.cart = defaultCartState().cart;
    state.session = undefined;
    state.cartLoading = false;
    state.cartItemsLoading = false;
    state.cartDeliveryLoading = false;
    state.cartShippingLoading = false;
    state.shippingAddressLoading = false;
    state.shippingTemplateLoading = false;
  },
  cartLoading: (state, loading: boolean) => {
    state.cartLoading = loading;
  },
  setCartAndSession: (state, data: { cart: Cart, session: Session}) => {
    state.session = data.session;
    data.cart.total = data.cart.total;
    state.cart = data.cart;
  },
  cartItemsLoading: (state, loading: boolean) => {
    state.cartItemsLoading = loading;
  },
  cartDeliveryLoading: (state, loading: boolean) => {
    state.cartDeliveryLoading = loading;
  },
  setCartDelivery: (state, detail: DeliveryDetail) => {
    state.cart.deliverTo = detail;
  },
  cartShippingLoading: (state, loading: boolean) => {
    state.cartShippingLoading = loading;
  },
  setCartShipping: (state, shipping: string) => {
    state.cart.shippingFeeId = shipping;
  },
  // setSession: (state, data: Session) => {
  //   state.session = data;
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export interface CartState {
  cart: Cart;
  cartLoading: boolean;
  cartItemsLoading: boolean;
  cartDeliveryLoading: boolean;
  cartShippingLoading: boolean;
  shippingTemplateLoading: boolean;
  shippingAddressLoading: boolean;
  session: Session | undefined;
}

export interface Cart {
  id: string;
  sessionId: string;
  customerId: string;
  customerName: string;
  paid: boolean;
  paidAt: string;
  items?: CartItem[];
  mappedCartItems: MappedCartItem[];
  deliverTo?: DeliveryDetail;
  shippingFeeId: string;
  shippingFee?: ShippingFee;
  total: number;
}
export interface MappedCartItem {
  itemId: string;
  code: string;
  name: string;
  price: number;
  quantity: number;
}


export interface CartItem {
  itemId: string;
  price: number;
  quantity: number;
}

export interface DeliveryDetail {
  name: string;
  contactNo: string;
  address: DeliveryAddress;
  fullAddress?: string;
}

export interface DeliveryAddress {
  address: string;
  postcode?: string;
  city: string;
  state: string;
  country: string;
}
