
import Vue from 'vue';
import homeDrawerView from './home-view/home-drawer-view.vue';
import homeFooterView from './home-view/home-footer-view.vue';

export default Vue.extend({
  name: 'home-view',
  components: { homeDrawerView, homeFooterView },
  data() {
    return {
      snackbar: false,
      drawer: false,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' },
      ],
    };
  },
  created() {
    this.$vuetify.theme.dark = false;
  },
  mounted() {
    const ck = localStorage.getItem('bb_ck_acknowledgement');
    if (!ck) {
      setTimeout(() => {
        this.snackbar = true;
      }, 3000);
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollTo(id: string) {
      const element = document.getElementById(id) as HTMLElement;
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    },
    toggleDrawer(toggled: boolean) {
      this.drawer = toggled;
    },
    closeSnackbar() {
      this.snackbar = false;
      localStorage.setItem('bb_ck_acknowledgement', 'ok');
    }
  }
});
