
import { Session } from '@/store/session-store';
import Vue from 'vue';
import { DataOptions } from 'vuetify';
import { ActionPayload, mapGetters } from 'vuex';
import { getCookie, setCookie } from '@/helper/browser';
import { Order } from '@/store/order-store';
import updateCourier from './order-list/update-courier.vue';
import copyTextTooltip from '@/components/shared/copy-text-tooltip/copy-text-tooltip.vue';
import noData from '@/components/shared/select/no-data.vue';

let unsubscribeAction: () => void;

export default Vue.extend({
  name: 'order-list',
  components: {
    updateCourier,
    copyTextTooltip,
    noData
  },
  data: () => ({
    timer: 0 as any,
    searchTimeout: 0,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'order_module.order_table.customer', value: 'customerName' },
      { text: 'order_module.order_table.orders', value: 'items', sortable: false },
      { text: 'order_module.order_table.payment', value: 'paid', sortable: false },
      { text: 'order_module.order_table.paid_at', value: 'paidAt' },
      { text: 'order_module.order_table.total', value: 'total' },
      { text: 'order_module.order_table.delivery_details', value: 'deliverTo', sortable: false, width: '30%' },
    ],
    editedIndex: -1,
    editedItem: { },
    defaultItem: { },
    date: '',
    date2: '',
    menu: false,
    menu2: false,
    selectedId: '',
    selectedPaymentStatus: 2,
    sorting: {
      column: '',
      desc: false
    },
    paymentStatusModel: [
      { id: 0, text: 'order_module.paid' },
      { id: 1, text: 'order_module.unpaid' },
      { id: 2, text: 'general_module.all' },
    ],
    filters: {
      page: 1,
      size: 20,
      paymentStatus: undefined as boolean | undefined,
      sessionId: '',
      search: '',
      sorting: {
        column: '',
        desc: false
      }
    },
    courierEditorMode: 'create'
  }),

  computed: {
    sessionsMapped(): { name: string; id: string }[] {
      return (this.session.list as Session[]).map((s) => ({
        name: s.name,
        id: s.id,
      }));
    },
    sessionCount(): number {
      return (this.session.list as Session[]).length || 0;
    },
    ...mapGetters('orderStore', ['orders', 'listLoading', 'totalOrders', 'updatingCourier', 'orderSession']),
    ...mapGetters('sessionStore', ['session']),
  },
  created () {
    this.$store.dispatch('sessionStore/getSessionListAction');

    if (this.$route.query.s) {
      this.filters.sessionId = this.$route.query.s.toString();
    } else {
      const orderCookie = getCookie('bb_usr_settings');
      if (orderCookie?.order?.sessionId) {
        this.filters.sessionId = orderCookie.order.sessionId!;
      } else if (this.session.list?.length) {
        this.filters.sessionId = this.session.list[0].id;
      }
    }

    if (this.filters.sessionId) {
      this.$store.dispatch('orderStore/getOrdersAction', this.filters);
    }
    this.selectedId = this.filters.sessionId;

    unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
      switch (action.type) {
        case 'userStore/selectPage': {
          this.selectedId = '';
          this.selectedPaymentStatus = 2;
          this.filters.search = '';
          break;
        }
        case 'orderStore/UPDATE_COURIER_OK': {
          this.$store.dispatch('uiStore/setSnackbar', {
            color: 'success',
            action: 'UPDATE_COURIER_OK',
            text: 'Courier and tracking details has been updated.'
          });
          this.updateList();
          break;
        }
        case 'orderStore/UPDATE_COURIER_FAILED': {
          this.$store.dispatch('uiStore/setSnackbar', {
            color: 'error',
            action: 'UPDATE_COURIER_FAILED',
            text: 'Failede to update courier and tracking details.'
          });
          break;
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeAction) unsubscribeAction();
  },
  methods: {
    // methods
    updateList() {
      this.$store.dispatch('orderStore/getOrdersAction', this.filters);
    },
    // events
    onSessionIdChange() {
      this.filters.sessionId = this.selectedId;
      this.updateList();
      const cookie = { order: { sessionId: this.selectedId }};
      setCookie('bb_usr_settings', cookie);
    },
    onPaymentStatusChange() {
      if (this.selectedPaymentStatus === 0) {
        this.filters.paymentStatus = true;
      } else if (this.selectedPaymentStatus === 1) {
        this.filters.paymentStatus = false;
      } else {
        delete this.filters.paymentStatus;
      }
      this.updateList();
    },
    onOptions(options: DataOptions) {
      this.sorting.column = options.sortBy[0];
      this.sorting.desc = options.sortDesc[0];
      this.filters.sorting = this.sorting;
      this.filters.page = options.page;
      this.filters.size = options.itemsPerPage;
      this.updateList();
    },
    onPaginationChange(options: DataOptions) {
      this.filters.page = options.page;
      this.filters.size = options.itemsPerPage;
    },
    onSearch() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.updateList();
      }, 1000);
    },
    onUpdateShipping(cartId: string) {
      if (!cartId) return;
      const order = (this.orders as Order[]).find((o) => o.id === cartId);
      if (!order || !order.paid) return;

      if (!order.deliveryTrackingNumber || !order.deliveryServiceProvider) {
        alert('Please fill in Courier name and Tracking number');
      } else if (order.deliveryTrackingNumber && order.deliveryServiceProvider) {
        // this.$store.dispatch('orderStore/updateCourier', {
        //   cartId,
        //   sessionId: this.filters.sessionId,
        //   deliveryTrackingNumber: order.deliveryTrackingNumber,
        //   deliveryServiceProvider: order.deliveryServiceProvider,
        // });
      }
    },
    onCourierUpdated(result: { cartId: string; courier: string; trackingNumber: string }) {
      // console.log(result);
      this.$store.dispatch('orderStore/updateCourier', {
        cartId: result.cartId,
        sessionId: this.filters.sessionId,
        deliveryTrackingNumber: result.trackingNumber,
        deliveryServiceProvider: result.courier,
      });
    },
    onRefresh() {
      this.updateList();
    }
  },
});
