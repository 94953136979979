import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';

const namespaced = true;
const defaultAuthState = (): AuthState => {
  return {
    authenticating: false,
    verifying: false,
    token: ''
  };
};

const state = defaultAuthState();

const getters: GetterTree<AuthState, RootState> = {
  authenticating: (state): boolean => state.authenticating,
  verifying: (state): boolean => state.verifying,
  token: (state) => state.token,
};

const actions: ActionTree<AuthState, RootState> = {
  resetAuthState({ commit }) {
    commit('resetAuthState');
  },
  setAuthenticating({ commit }, params: any) {
    commit('mutateAuthenticating', params.loading);
  },
  setVerifying({ commit }, params: any) {
    commit('mutateVerifying', params.loading);
  },
  setAuth({ commit, dispatch }) {
    const { id_token } = JSON.parse(localStorage.getItem('bb_auths') || '{}');
    commit('setToken', id_token);
    dispatch('userStore/getTenant', null, { root: true });
  }
};

const mutations: MutationTree<AuthState> = {
  resetAuthState: (state) => {
    Object.assign(state, defaultAuthState());
  },
  mutateAuthenticating: (state, loading: boolean) => {
    state.authenticating = loading;
  },
  mutateVerifying: (state, loading: boolean) => {
    state.verifying = loading;
  },
  setToken: (state, token: string) => {
    state.token = token;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export interface AuthState {
  authenticating: boolean;
  verifying: boolean;
  token: string;
}
