
import Vue from 'vue';
// import { sampleProducts } from '../../models/shared/sampleData';

export default Vue.extend({
  name: 'product-component',
  data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'productName',
          width: '17%'
        },
        { text: 'Product id', value: 'productId', width: '16%' },
        { text: 'Code', value: 'productCode', width: '15%' },
        { text: 'Price', value: 'price', width: '11%' },
        { text: 'Quantity', value: 'quantity', width: '10%' },
        // { text: 'Weight', value: 'weight', width: '10%' },
        { text: 'Created at', value: 'createdAt', width: '16%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '5%' },
      ],
      statusSelect: 'PAID',
      selects: ['PAID', 'PENDING_PAYMENT', 'SHIPPED', 'COMPLETED', 'ALL'],
      sessions: ['Live session name 1', 'Live session name 2', 'Live session name 3', 'Live session name 4', 'Live session name 5'],
      products: [] as any[],
      editedIndex: -1,
      editedItem: {
        productName: '',
        productCode: '',
        price: 0,
        quantity: 0,
      },
      defaultItem: {
        productName: '',
        productCode: '',
        price: 0,
        quantity: 0,
      },
      paginationa: {
        size: 25,
        page: 1
      }
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Product' : 'Edit Product';
      },
    },

    watch: {
      dialog (val: any) {
        if (!val) this.close();
      },
      dialogDelete (val: any) {
        if (!val) this.closeDelete();
      },
    },

    created () {
      this.initialize();
    },

    methods: {
      initialize () {
        this.products = [];
      },

      editItem (item: any) {
        this.editedIndex = this.products.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      deleteItem (item: any) {
        this.editedIndex = this.products.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      deleteItemConfirm () {
        this.products.splice(this.editedIndex, 1);
        this.closeDelete();
      },

      close () {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      closeDelete () {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.products[this.editedIndex], this.editedItem);
        } else {
          this.products.unshift(this.editedItem);
        }
        this.close();
      },
      addProduct() {
        // const template = `{
        //   "productId":"{{guid}}",
        //   "productName":"{{random "Salmon 1kg" "Salmon 3kg" "Dory 1 kg" "Alaskan King Crab 1pc" "Australian Lobster 1pc" "Kampung Fish 1kg" "Tiger Prawn 2kg" "Japanese Fresh Scallop 20pc"}}",
        //   "price": {{float 50 400 "0.00"}},
        //   "quantity": {{int 1 5}},
        //   "productCode": "{{guid}}",
        //   "userId": "{{guid}}",
        //   "username": "{{firstName}} {{lastname}}",
        //   "status": "{{random "PAYMENT_PENDING" "SHIPPED" "COMPLETED"}}",
        //   "email": "{{email}}",
        //   "orderDate": "{{date "2019" "2021"}}"
        //   }`;
        // const result = dummyjson.parse(template);
        // const json = JSON.parse(result);
        const json = {
          productId: 'c37d240d-0b53-4ef3-8d09-ee94af67fed4',
          productName: 'Tiger Prawn 2kg',
          price: 311.19,
          quantity: 2,
          productCode: '607f9f6dbb7a23faa8b66ca2',
          userId: '607f9f6dc7d570bf84095027',
          username: 'Alyssa Carroll',
          status: 'PENDING_PAYMENT',
          email: 'alyssacarroll@oronoko.com',
          orderDate: 'Fri Mar 19 2021 14:22:30 GMT+0800 (Malaysia Time)'
        };

        this.products.unshift(json);
      },
      pageChanged(page: any) {
        page = page || undefined;
        this.$router.replace({ query: {...this.$route.query, page }});
      },
      sizeChanged(size: any) {
        size = size || undefined;
        this.$router.replace({ query: {...this.$route.query, size }});
      },
      statusChanged() {
        const element  = this.$refs.statusSelect as HTMLElement;
        element.blur();
      },
      sessionChanged() {
        const element  = this.$refs.sessionSelect as HTMLElement;
        element.blur();
      }
    },
});
