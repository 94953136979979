import axios from 'axios';
import mime from 'mime-types';


export async function upload(files: File[]): Promise<string[]> {
  try {
    const urls = await getPresignedUrl(files);
    return await putFiles(urls);
  } catch (err) {
    console.error(err);
    throw err;
  }
}


async function getPresignedUrl(files: File[]): Promise<typeof presignedUrls> {
  const presignedUrls: PutFile[] = [];

  try {
    await Promise.all(files.map(async (f: File) => {
      const filename = `${Math.round((new Date() as any)).toString()}-${f.name}`;
      const url = `${process.env.VUE_APP_UPLOAD_PRESIGNED_HOST}/${filename}`;
      const mimeType = mime.lookup(filename);
      // console.log('mimetype', mimeType);

      const response = await axios.get(url, {
        headers: {
          'Content-Type': mimeType
        },
        data: null
      });

      presignedUrls.push({
        file: f,
        url: response.data,
        name: filename,
        mime: mimeType
      });
    }));
  } catch (err) {
    console.error(err);
    throw err;
  }

  return presignedUrls;
}

async function putFiles(data: PutFile[]): Promise<string[]> {
  const fileUrls: string[] = [];

  try {
    await Promise.all(data.map(async (d: PutFile) => {
      await axios.put(d.url, d.file, {
        headers: {
          'Content-Type': d.mime,
        }
      });
      fileUrls.push(`${process.env.VUE_APP_UPLOAD_PUT_HOST}/${d.name}`);
    }));
  } catch (err) {
    console.error(err);
    throw err;
  }

  return fileUrls;
}

interface PutFile {
  file: File;
  url: string;
  name: string;
  mime: string | false;
}
