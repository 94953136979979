import { Session, ShippingFee } from '@/store/session-store';
import axios from 'axios';
import moment from 'moment';

async function getPrices(): Promise<any[]> {
  try {
    const response = await axios.get('https://dev-morbius-temp.s3-ap-southeast-1.amazonaws.com/price.json');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

async function getInventories(): Promise<any[]> {
  try {
    const response = await axios.get('https://dev-morbius-temp.s3-ap-southeast-1.amazonaws.com/inventory.json');
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

async function getSession(id: string, token: string): Promise<Session> {
  try {
    const response = await axios.get<Session>(`${process.env.VUE_APP_API_HOST}/session/${id}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function getSessions(pageId: string, token: string): Promise<Session[]> {
  try {
    pageId = pageId || '';
    const response = await axios.get<Session[]>(`${process.env.VUE_APP_API_HOST}/session/byPageId/${pageId}`, {
      headers: { Authorization:  token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function putSession(body: any, token: string): Promise<string> {
  const requestBody = {
    pageId: body.pageId,
    name: body.name,
    postURL: body.postUrl,
    importFileURL: body.fileUrl,
    shippingFees: body.shippingFees,
    ignoreInventory: true,
    currency: body.currency.toLowerCase(),
    expiredAt: moment(body.expiredAt, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
  };

  try {
    const response = await axios.put(`${process.env.VUE_APP_API_HOST}/session`, requestBody, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function patchSession(body: any, token: string): Promise<string> {
  const requestBody = {
    pageId: body.pageId,
    name: body.name,
    postURL: body.postUrl,
    importFileURL: body.fileUrl,
    // shippingFees: body.shippingFees,
    ignoreInventory: true,
    currency: body.currency.toLowerCase(),
    expiredAt: moment(body.expiredAt, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
  };

  try {
    const response = await axios.patch(`${process.env.VUE_APP_API_HOST}/session/${body.id}`, requestBody, {
      headers: { Authorization: token }
    });

    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function postSessionShippingFees(sessionId: string, shippingFees: ShippingFee, token: string): Promise<ShippingFee[]> {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_HOST}/session/${sessionId}/shippingFees`, shippingFees, {
      headers: { Authorization: token }
    });

    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function deleteSession(id: string, token: string): Promise<string> {
  try {
    const response = await axios.delete(`${process.env.VUE_APP_API_HOST}/session/${id}`, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function activateSession(id: string, token: string): Promise<string> {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_HOST}/session/${id}/activate`, null , {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function deactivateSession(id: string, token: string): Promise<string> {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_HOST}/session/${id}/deactivate`, null, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

export {
  getInventories,
  getPrices,
  getSession,
  getSessions,
  putSession,
  patchSession,
  deleteSession,
  activateSession,
  deactivateSession,
  postSessionShippingFees
};
