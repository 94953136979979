
import Vue from 'vue';
import { themes } from '@/models/shared/themes';

export default Vue.extend({
  name: 'theme-selection-component',
  data() {
    return {
      selected: localStorage.getItem('bb_lang') || 'EN',
      themes
    };
  },
  created() {
    const primary = localStorage.getItem('bb_theme');
    if (!primary) return;

    if (this.themes.light.find((t) => t.primary === primary)) {
      this.themeClicked(this.themes.light.find((t) => t.primary === primary), 'light');
    } else {
      this.themeClicked(this.themes.dark.find((t) => t.primary === primary), 'dark');
    }
  },
  methods: {
    themeClicked(colors: any, theme: string) {
      this.$vuetify.theme.options.customProperties = true;
      if (theme === 'light') {
        Object.keys(colors).forEach((i) => {
          this.$vuetify.theme.dark = false;
          this.$vuetify.theme.themes.light[i] = colors[i];
        });
      } else {
        Object.keys(colors).forEach((i) => {
          this.$vuetify.theme.dark = true;
          this.$vuetify.theme.themes.dark[i] = colors[i];
        });
      }

      localStorage.setItem('bb_theme', colors.primary);
    }
  }
});
