
import Vue from 'vue';

export default Vue.extend({
  name: 'copy-text-tooltip',
  props: {
    content: { type: String, default: '' },
    tooltip: { type: String, default: 'Click to copy' },
    responseTooltip: { type: String, default: 'Copied!' },
    responseMs: { type: Number, default: 1500 },
  },
  data: (): {
    response: boolean
  } => ({
    response: false
  }),
  methods: {
    onClick() {
      this.response = true;
      setTimeout(() => {
        this.response = false;
      }, this.responseMs);
    }
  },
});
