
import Vue from 'vue';
import ProductComponent from '@/components/product/product-component.vue';

export default Vue.extend({
  name: 'product-view',
  components: {
    ProductComponent
  }
});
