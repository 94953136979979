import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import cn from './locales/cn.json';
import bm from './locales/bm.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem('bb_lang') || 'en',
  messages: {
    en,
    bm,
    cn
  }
});
