
import Vue from 'vue';
export default Vue.extend({
  name: 'card-title-component',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
});
