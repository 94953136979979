
import Vue from 'vue';
import cardTitleComponent from '@/components/shared/card/card-title-component.vue';
// import subscriptionSetting from './subscription/subscription.vue';
import stripePayment from './stripe-payment/stripe-payment.vue';
import shippingMethod from './shipping-method/shipping-method-component.vue';
import account from './account/account.vue';
import usernameInput from './account/username-input.vue';
// import ShippingMethodComponent from './sub-components/shipping-method-component.vue';

export default Vue.extend({
  name: 'setting-menu',
  components: {
    cardTitleComponent,
    stripePayment,
    shippingMethod,
    account,
    usernameInput
  },
});
