
import Vue from 'vue';
export default Vue.extend({
  name: 'sale-detail-help',
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    dialogClicked() {
      this.dialog = !this.dialog;
    },
  }
});
