import { upload } from '@/services/util-service';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import i18n from '../i18n';

const namespaced = true;
const defaultUtilState = (): UtilState => {
  return {
    upload: {
      uploadedFileUrls: [],
      uploadFileLoading: false
    }
  };
};

const state = defaultUtilState();

const getters: GetterTree<UtilState, RootState> = {
  upload: (state) => state.upload
};

const actions: ActionTree<UtilState, RootState> = {
  async uploadFile({ commit, dispatch }, params) {
    commit('setUploadFileLoading', true);
    commit('setUploadedFileUrls', []);

    try {
      const { files } = params;
      const urls = await upload(files);
      commit('setUploadedFileUrls', urls);
      dispatch('uiStore/setSnackbar', {
        color: 'success',
        action: 'upload_file',
        text: i18n.t('general_module.file_uploaded')
      }, { root: true });
    } catch (err) {
      dispatch('uiStore/setSnackbar', {
        color: 'error',
        action: 'upload_file',
        text: i18n.t('general_module.file_not_uploaded')
      }, { root: true });
    } finally {
      commit('setUploadFileLoading', false);
    }
  }
};

const mutations: MutationTree<UtilState> = {
  setUploadFileLoading: (state: UtilState, loading: boolean) => {
    state.upload.uploadFileLoading = loading;
  },
  setUploadedFileUrls: (state: UtilState, urls: string[]) => {
    state.upload.uploadedFileUrls = urls;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export interface UtilState {
  upload: Upload;
}

export interface Upload {
  uploadedFileUrls: string[];
  uploadFileLoading: boolean;
}
