
import Vue from 'vue';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';


export default Vue.extend({
  components: {
    StripeElementPayment,
  },
  data(): {
    stripe: any;
    stripeTestMode: boolean;
    loading: boolean;
    ready: boolean;
  } {
    return {
      stripe: {
        pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        account: process.env.VUE_APP_STRIPE_ACCOUNT_ID,
        elementsOptions: {
          appearance: {
            theme: 'flat'
          },
          clientSecret: this.$route.query.cs.toString()
        },
        confirmParams: {
          return_url: `${process.env.VUE_APP_HOST_URL}/cart/payment-success?cid=${this.$route.query.cart}`,
        },
      },
      stripeTestMode: process.env.VUE_APP_STRIPE_TEST_MODE === 'true' ? true : false,
      loading: false,
      ready: false,
    };
  },
  created() {
    // created
  },
  methods: {
    onPay() {
      const paymentElement = this.$refs.paymentRef as any;
      paymentElement.submit();
    },
    onLoading(loading: boolean) {
      this.loading = loading;
    },
    onReady() {
      this.ready = true;
    }
  }
});
