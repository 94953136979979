import { Order } from '@/store/order-store';
import axios from 'axios';

async function getOrders(filters: OrderFilters, token: string): Promise<{ data: Order[], total: number }> {
  try {
    const params: string[] = [];
    const ordering: string[] = [];
    // Object.keys(filters).forEach((k) => {
    //   if (k === 'sessionId') return;
    //   if (k === 'search' && filters.search) {
    //     params.push(`&filters=${encodeURIComponent(`customerName:iLike:%${filters.search}%`)}`);
    //   }
    //   if (k === 'paymentStatus' && (filters.paymentStatus === true || filters.paymentStatus === false)) {
    //     params.push(`&filters=${encodeURIComponent(`paid:eq:${filters.paymentStatus ? 'true' : 'false'}`)}`);
    //   }
    //   if (k === 'sorting' && filters.sorting?.column) {
    //     ordering.push(`&orders=${encodeURIComponent(`${filters.sorting?.column}:${filters.sorting?.desc ? 'desc' : 'asc'}`)}`);
    //   }
    // });
    Object.keys(filters).forEach((k) => {
      if (k === 'sessionId') return;
      if (k === 'search' && filters.search) {
        params.push(`&filters[${params.length}]=${encodeURIComponent(`customerName:iLike:%${filters.search}%`)}`);
      }
      if (k === 'paymentStatus' && (filters.paymentStatus === true || filters.paymentStatus === false)) {
        params.push(`&filters[${params.length}]=${encodeURIComponent(`paid:eq:${filters.paymentStatus ? 'true' : 'false'}`)}`);
      }
      if (k === 'sorting' && filters.sorting?.column) {
        ordering.push(`&orders=${encodeURIComponent(`${filters.sorting?.column}:${filters.sorting?.desc ? 'desc' : 'asc'}`)}`);
      }
    });

    const url = `${process.env.VUE_APP_API_HOST}/session/${filters.sessionId}/carts?page=${filters.page}&size=${filters.size}${params.join('')}${ordering.join('')}`;
    const response = await axios.get<{ data: Order[], total: number }>(url, {
      headers: { Authorization: token }
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function updateDelivery(data: UpdateDeliveryParams, token: string): Promise<boolean> {
  const url = `${process.env.VUE_APP_API_HOST}/session/${data.sessionId}/carts/updateDelivery`;
  const body = [{
    id: data.cartId,
    deliveryTrackingNumber: data.deliveryTrackingNumber,
    deliveryServiceProvider: data.deliveryServiceProvider
  }];
  const response = await axios.post(url, body, {
    headers: { Authorization: token }
  });
  if (response.status === 200) return true;
  else return false;
}

export interface UpdateDeliveryParams {
  cartId: string;
  sessionId?: string;
  deliveryTrackingNumber: string;
  deliveryServiceProvider: string;
}

export interface OrderFilters {
  sessionId: string;
  paymentStatus?: boolean;
  search?: string;
  page?: number;
  size?: number;
  sorting?: {
    column: 'customerName' | 'paidAt' | 'shippingFee.name' | 'total';
    desc: boolean;
  };
}

export {
  getOrders,
  updateDelivery,
};
