
import Vue from 'vue';
import { email, required } from 'vuelidate/lib/validators';
export default Vue.extend({
  name: 'home-component',
  data: () => ({
    emailInput: ''
  }),
  validations() {
    return {
      emailInput: { required, email }
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    emailInputErr() {
      const errs: string[] = [];
      if (this.$v.emailInput) {
        if (!this.$v.emailInput.$dirty) return errs;
        if (!this.$v.emailInput.required) errs.push('Email is required.');
        if (!this.$v.emailInput.email) errs.push('Please enter a valid email address.');
      }
      return errs;
    }
  },
  methods: {
    scrollTo(id: string) {
      const element = document.getElementById(id) as HTMLElement;
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    },
    onSubscribe() {
      this.$v.emailInput.$touch();
      if (this.$v.emailInput.$invalid) return;
      (this.$refs.mceEmail as HTMLInputElement).value = this.emailInput;
      (this.$refs.subscribe as HTMLButtonElement).click();
      this.emailInput = '';
      this.$v.emailInput.$reset();
    }
  }
});
