
import { ROUTE_NAME } from '@/router';
import { AlertSetting, ALERT_NAME } from '@/store/ui-store';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'alert',
  props: {
    type: { type: String, default: '' },
    showSettings: { type: Boolean, default: false },
    text: { type: String, default: '' },
  },
  data: (): {
    show: boolean,
    typeText: string,
    color: string
  } => ({
    show: true,
    typeText: '',
    color: '',
  }),
  computed: {
    hasActiveAlerts(): boolean {
      if (this.alerts && this.alerts.length) {
        if ((this.alerts as AlertSetting[]).some((a) => a.show === true)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    ...mapGetters('uiStore', ['alerts'])
  },
  created() {
    // if (this.type?.toLowerCase() === 'info') {
    //   this.typeText = 'INFO: ';
    //   this.color = 'light-blue';
    // } else if (this.type?.toLowerCase() === 'warn') {
    //   this.typeText = 'WARNING: ';
    //   this.color = 'orange';
    // } else if (this.type?.toLowerCase() === 'error') {
    //   this.typeText = 'ERROR: ';
    //   this.color = 'red';
    // }
  },
  methods: {
    // events
    onClose(name: ALERT_NAME) {
      this.$store.dispatch('uiStore/removeAlertAction', { name });
    },
    onGoToSettings(name: ALERT_NAME) {
      if (this.$route.name !== ROUTE_NAME.SETTINGS_MAIN) {
        this.$router.push({
          name: ROUTE_NAME.SETTINGS_MAIN
        });
      }
      this.$store.dispatch('uiStore/removeAlertAction', { name });
    }
  }
});

