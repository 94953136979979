
// import backButton from '@/components/shared/back-button/back-button.vue';
// import cardTitleComponent from '@/components/shared/card/card-title-component.vue';
import { tenantSubscribe, tenantUnsubscribe } from '@/services/user-service';
import Vue from 'vue';
import { ActionPayload, mapGetters } from 'vuex';

let unsubscribeAction: () => void;

export default Vue.extend({
  name: 'setting-subscription',
  // components: { backButton, cardTitleComponent },
  data: (): {
    subscribeLoading: boolean;
    unsubscribeLoading: boolean;
  } => ({
    subscribeLoading: false,
    unsubscribeLoading: false
  }),
  computed: {
    ...mapGetters('userStore', ['userStore']),
    ...mapGetters('authStore', ['token']),
    ...mapGetters('uiStore', ['confirmDialog']),
  },
  created() {
    unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
        if (action.type === 'uiStore/confirmDialogOk' && this.confirmDialog.from === this.$options.name) {
          this.unsubscribe();
        }
      });
  },
    beforeDestroy() {
      if (unsubscribeAction) unsubscribeAction();
    },
  methods: {
    // events
    onSubscribe() {
      this.subscribeLoading = true;
      tenantSubscribe(this.token).then((url) => {
        if (url) window.location.href = url;
        else throw Error('Subscribe URL missing');
      }).catch((err) => {
        console.error(err);
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'tenant_subscribe',
          text: 'Unable to subscribe to the service, please try again later.'
        });
      }).finally(() => {
        this.subscribeLoading = false;
      });
    },
    onUnsubscribe() {
        this.$store.dispatch('uiStore/setConfirmDialog', {
          from: this.$options.name,
          title: 'Unsubscribe BillerBee',
          text: 'Are you really sure you want to unsubscribe from BillerBee?'
        });
    },
    // methods
    unsubscribe() {
      this.subscribeLoading = true;
      tenantUnsubscribe(this.token).then(() => {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'success',
          action: 'tenant_unsubscribe',
          text: 'Successfully unsubscribed from BillerBee.'
        });
      }).catch((err) => {
        console.error(err);
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'tenant_unsubscribe',
          text: 'Unable to unsubscribe, please try again later.'
        });
      }).finally(() => {
        this.subscribeLoading = false;
      });
    }
  }
});
