
import Vue from 'vue';
export default Vue.extend({
  name: 'back-button',
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
  },
  methods: {
    // events
    onBackClick() {
      this.$router.go(-1);
    }
  }
});
