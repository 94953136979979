import { getTenant, getTenantPages, getTenantSubscriptions } from '@/services/user-service';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';

const namespaced = true;
const defaultUserState = (): UserState => {
  return {
    tenant: {
      id: '',
      name: '',
      email: '',
      picture: '',
      stripeAccount: '',
      stripeAccountPaymentEnabled: undefined,
    },
    tenantSubscriptions: [],
    pages: [],
    selectedPage: undefined
  };
};

const state = defaultUserState();

const getters: GetterTree<UserState, RootState> = {
  userStore: (state): UserState => state,
  tenant: (state) => state.tenant,
  pages: (state) => state.pages
};

const actions: ActionTree<UserState, RootState> = {
  resetUserState({ commit }) {
    commit('resetUserState');
  },
  async getTenantSubscription({ commit, rootState }): Promise<any> {
    try {
      const subscriptions = await getTenantSubscriptions(rootState.authStore.token);
      commit('setTenantSubscriptions', subscriptions);
    } catch (err) {
      console.error(err);
    }
  },
  async getTenant({ commit, rootState, dispatch }): Promise<void> {
    try {
      const data = await getTenant(rootState.authStore.token);
      if (data) {
        commit('setTenant', data);
        dispatch('getTenantPages');
      }
    } catch (err) {
      console.error(err);
    }
  },
  async getTenantPages({ commit, rootState }): Promise<void> {
    try {
      const data = await getTenantPages(rootState.authStore.token);
      if (data) {
        commit('setTenantPage', { pages: data });
      }
    } catch (err) {
      console.log(err);
    }
  },
  selectPage({ commit }, params: TenantPage) {
    if (params) {
      commit('setSelectedPage', params);
    }
  }
};

const mutations: MutationTree<UserState> = {
  resetUserState: (state) => {
    Object.assign(state, defaultUserState());
  },
  setTenant: (state, user: Tenant) => {
    state.tenant = user;
  },
  setTenantPage: (state, data: { pages: TenantPage[] }) => {
    state.pages = data.pages;

    if (state.selectedPage) {
      const index = state.pages.findIndex((p) => p.id === state.selectedPage!.id);
      if (index > -1) {
        state.selectedPage = state.pages[index];
      }
    }
  },
  setTenantSubscriptions: (state, subscriptions: TenantSubscription[]) => {
    state.tenantSubscriptions = subscriptions;
  },
  setSelectedPage: (state, page: TenantPage) => {
    state.selectedPage = page;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export interface UserState {
  tenant: Tenant;
  tenantSubscriptions: TenantSubscription[];
  pages: TenantPage[];
  selectedPage: TenantPage | undefined;
}

export interface Tenant {
  id: string;
  name: string;
  email: string;
  picture: string;
  stripeAccount: string;
  stripeAccountPaymentEnabled: boolean | undefined;
}

export interface TenantSubscription {
  expiredAt: Date;
  subscription: string;
}

export interface TenantPage {
  id: string;
  tenantId: string;
  name: string;
  expiresAt: string;
  username: string;
  haveFacebookAccess: boolean;
}
