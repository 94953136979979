import { render, staticRenderFns } from "./cart-view.vue?vue&type=template&id=2910449e&scoped=true&"
import script from "./cart-view.vue?vue&type=script&lang=ts&"
export * from "./cart-view.vue?vue&type=script&lang=ts&"
import style0 from "./cart-view.vue?vue&type=style&index=0&id=2910449e&prod&lang=scss&"
import style1 from "./cart-view.vue?vue&type=style&index=1&id=2910449e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2910449e",
  null
  
)

export default component.exports