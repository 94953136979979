
import { UserState } from '@/store/user-store';
import Vue from 'vue';
import { mapGetters, MutationPayload } from 'vuex';

let unsubscribeAction: () => void;
let unsubscribeMutation: () => void;

export default Vue.extend({
  name: 'select-page-dialog-component',
  data() {
    return {
      dialog: false,
      selected: '',
      continueLoading: false
    };
  },
  computed: {
    ...mapGetters('userStore', ['userStore']),
    ...mapGetters('uiStore', ['confirmDialog']),
  },
  mounted() {
    if (!this.userStore || !this.userStore.selectedPage) {
      this.displayDialog();
    }

    unsubscribeAction = this.$store.subscribeAction((action: any) => {
      if (action.type === 'uiStore/confirmDialogOk') {
        if (this.confirmDialog.from === this.$options.name) {
          this.dialog = false;
        }
      }
    });

    unsubscribeMutation = this.$store.subscribe((mutation: MutationPayload) => {
      switch (mutation.type) {
        case 'userStore/setTenantPage': {
          if (!this.userStore.selectedPage) {
            this.displayDialog();
          }
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeAction) unsubscribeAction();
    if (unsubscribeMutation) unsubscribeMutation();
  },
  methods: {
    displayDialog() {
      setTimeout(() => {
        this.dialog = true;
      }, 500);
    },
    onSkipClick() {
      this.$store.dispatch('uiStore/setConfirmDialog', {
        from: this.$options.name,
        title: this.$t('main.dialog.select_page_title'),
        text: this.$t('main.dialog.select_page_msg')
      });
    },
    onContinueClick() {
      if (this.selected) {
        this.continueLoading = true;
        const page = (this.userStore as UserState).pages.find((p) => p.id === this.selected);
        this.$store.dispatch('userStore/selectPage', page);

        setTimeout(() => {
          this.continueLoading = false;
          this.dialog = false;
        }, 200);
      }
    }
  }
});
