
import { ROUTE_NAME } from '@/router';
import Vue from 'vue';
import { ActionPayload } from 'vuex';

let unsubscribeAction: () => void;

export default Vue.extend({
  name: 'cart-payment-success',
  data(): {
    countdown: number,
    countdown2: number,
  } {
    return {
      countdown: 10000,
      countdown2: 2000,
    };
  },
  created() {
    const timer = setInterval(() => {
      this.countdown -= 1000;
      if (this.countdown === 0) {
        clearInterval(timer);
        this.toCart();
      }
    }, 1000);
    const timer2 = setInterval(() => {
      this.countdown2 -= 1000;
      if (this.countdown2 === 0) {
        clearInterval(timer2);
        this.$store.dispatch('cartStore/checkCartPaidAction', {
          cartId: this.$route.query?.cid
        });
      }
    });

    unsubscribeAction = unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
      switch (action.type) {
        case 'cartStore/CHECK_CART_PAID_OK': {
          if (action.payload.paid) {
            clearInterval(timer);
            this.toCart();
          }
          break;
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeAction) unsubscribeAction();
  },
  methods: {
    toCart() {
      this.$router.push({
        name: ROUTE_NAME.CART_COMPONENT,
        params: {
          cartId: this.$route.query?.cid?.toString() || ''
        }
      });
    }
  }
});
