
import { ActionPayload, mapActions, mapGetters, MutationPayload } from 'vuex';
import Vue from 'vue';
import { required, url, decimal } from 'vuelidate/lib/validators';
import mime from 'mime-types';
import moment from 'moment';
import backButton from '../shared/back-button/back-button.vue';
import cardTitleComponent from '../shared/card/card-title-component.vue';
import { ShippingTemplate } from '@/store/setting-store';
import { ROUTE_NAME } from '@/router';
import { UserState } from '@/store/user-store';
import { ShippingFee } from '@/store/session-store';
import saleDetailProduct from './sale-detail-product.vue';
import saleDetailHelp from './sale-detail-help.vue';

let unsubscribeAction: () => void;
let unsubscribeMutation: () => void;
const allowedTypes = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export default Vue.extend({
  name: 'sale-detail',
  components: {
    backButton,
    cardTitleComponent,
    saleDetailProduct,
    saleDetailHelp
  },
  validations: {
    form: {
      expiredAt: { required },
      shippingTemplateId: { required },
      name: { required },
      uploadField: { required },
      postUrl: { url, required },
      currency: { required },
      shippingFees: {
        $each: {
          fee: { decimal, required },
          name: { required },
        },
      },
    },
  },
  data(): {
    mode: string;
    menu: boolean;
    form: {
      name: string,
      shippingTemplateId: string;
      shippingFees: ShippingFee[];
      expiredAt: string;
      fileUrl: string;
      price: string;
      inventory: string;
      postUrl: string;
      uploadField: any;
      reuploadField: any;
      currency: string;
    };
    fileUrlHint: string;
    urlHelp: boolean;
    originalShippingFee: ShippingFee[]
  } {
    return {
      mode: '',
      menu: false,
      form: {
        name: '',
        shippingTemplateId: '',
        shippingFees: [
          {
            id: '',
            name: '',
            fee: 0,
            delete: false
          }
        ],
        expiredAt: '',
        fileUrl: '',
        price: '',
        inventory: '',
        postUrl: '',
        uploadField: null,
        reuploadField: null,
        currency: '',
      },
      fileUrlHint: '',
      urlHelp: false,
      originalShippingFee: []
    };
  },
  computed: {
    pageDisabled(): boolean {
      if (!this.userStore.selectedPage.haveFacebookAccess) {
        return true;
      }
      return false;
    },
    paymentDisabled(): boolean {
      if (!(this.userStore as UserState).tenant.stripeAccountPaymentEnabled) {
        return true;
      }
      return false;
    },
    paymentProcessing(): boolean {
      if ((this.userStore as UserState).tenant.stripeAccount && !(this.userStore as UserState).tenant.stripeAccountPaymentEnabled) {
        return true;
      }
      return false;
    },
    formTitle() {
      return `${this.session.mode === 'edit' ? this.$t('session_module.session_detail.edit_sale_session') : this.$t('session_module.session_detail.new_sale_session')}`;
    },
    expiredAtErr() {
      const errs: string[] = [];
      if (this.$v.form.expiredAt) {
        if (!this.$v.form.expiredAt.$dirty) return errs;
        if (!this.$v.form.expiredAt.required) {
          errs.push(this.$t('session_module.session_detail.errors.date_required'));
        }
      }
      return errs;
    },
    nameErr() {
      const errs: string[] = [];
      if (this.$v.form.name) {
        if (!this.$v.form.name.$dirty) return errs;
        if (!this.$v.form.name.required) {
          errs.push(this.$t('session_module.session_detail.errors.name_required'));
        }
      }
      return errs;
    },
    shippingTemplateIdErr() {
      const errs: string[] = [];
      if (this.$v.form.shippingTemplateId) {
        if (!this.$v.form.shippingTemplateId.$dirty) return errs;
        if (!this.$v.form.shippingTemplateId.required) {
          errs.push(this.$t('session_module.session_detail.errors.shipping_template_required'));
        }
      }
      return errs;
    },
    currencyErr() {
      const errs: string[] = [];
      if (this.$v.form.currency) {
        if (!this.$v.form.currency.$dirty) return errs;
        if (!this.$v.form.currency.required) {
          errs.push(this.$t('session_module.session_detail.errors.currency_required'));
        }
      }
      return errs;
    },
    uploadFieldErr() {
      const errs: string[] = [];
      if (this.$v.form.uploadField) {
        if (!this.$v.form.uploadField.$dirty) return errs;
        if (!this.$v.form.uploadField.required) {
          errs.push(this.$t('session_module.session_detail.errors.upload_file_required'));
        }
      }
      return errs;
    },
    postUrlErr() {
      const errs: string[] = [];
      if (this.$v.form.postUrl) {
        if (!this.$v.form.postUrl.$dirty) return errs;
        if (!this.$v.form.postUrl.required) {
          errs.push(this.$t('session_module.session_detail.errors.fb_post_url_required'));
        }
        if (!this.$v.form.postUrl.url) {
          errs.push(this.$t('session_module.session_detail.errors.invalid_url'));
        }
      }
      return errs;
    },
    todayDate() {
      return moment().format('YYYY-MM-DD');
    },
    activated() {
      return this.session?.entity?.activated || false;
    },
    ...mapGetters('utilStore', ['upload']),
    ...mapGetters('sessionStore', ['session']),
    ...mapGetters('settingStore', ['shipping']),
    ...mapGetters('uiStore', ['confirmDialog']),
    ...mapGetters('userStore', ['userStore']),
    ...mapGetters('generalStore', ['appSettings']),
  },
  watch: {
    'upload.uploadedFileUrls'(newValue: string[]) {
      if (newValue.length) {
        this.form.fileUrl = newValue[0];
        this.fileUrlHint = 'File uploaded: ' + newValue[0];
      }
    },
  },
  beforeCreate() {
    if (this.$route.name === ROUTE_NAME.SALE_CREATE) {
      this.$store.dispatch('sessionStore/sessionDetailMode', {
        mode: 'create'
      });
    } else if (this.$route.name === ROUTE_NAME.SALE_EDIT) {
      this.$store.dispatch('sessionStore/sessionDetailMode', {
        mode: 'edit'
      });
      this.$store.dispatch('sessionStore/getSessionAction', {
        sessionId: this.$route.params.id
      });
    }
  },
  created() {
    this.$store.dispatch('settingStore/getShippingTemplateList');

    unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
      if (action.type === 'uiStore/confirmDialogOk' && this.confirmDialog.from === this.$options.name) {
        this.resetForm();
        this.$router.push({
          name: ROUTE_NAME.SALE_LIST
        });
      } else if (action.type === 'sessionStore/EDIT_SESSION_OK') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'success',
          action: 'EDIT_SESSION_OK',
          text: this.$t('session_module.session_detail.messages.edit_session_ok')
        });
      } else if (action.type === 'sessionStore/EDIT_SESSION_FAILED') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'success',
          action: 'EDIT_SESSION_FAILED',
          text: this.$t('session_module.session_detail.messages.edit_session_failed')
        });
      } else if (action.type === 'sessionStore/EDIT_SESSION_SHIPPING_FAILED') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'EDIT_SESSION_SHIPPING_FAILED',
          text: this.$t('session_module.session_detail.messages.edit_session_shipping_failed')
        });
      } else if (action.type === 'sessionStore/CREATE_SESSION_OK') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'success',
          action: 'CREATE_SESSION_OK',
          text: this.$t('session_module.session_detail.messages.create_session_ok')
        });
      } else if (action.type === 'sessionStore/CREATE_SESSION_FAILED') {
        const apiErrMsg = action.payload.apiErrCode ? this.$t(`backend_api_module.error_code.${action.payload.apiErrCode}`) : '';
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'CREATE_SESSION_FAILED',
          text: this.$t('session_module.session_detail.messages.create_session_failed', {
            0: apiErrMsg
          })
        });
      } else if (action.type === 'sessionStore/CREATE_SESSION_FAILED_PAGEID_MISSING') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'CREATE_SESSION_FAILED_PAGEID_MISSING',
          text: this.$t('session_module.session_detail.messages.create_session_failed_pageid_missing')
        });
      } else if (action.type === 'sessionStore/GET_SESSION_FAILED') {
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'GET_SESSION_FAILED',
          text: this.$t('session_module.session_detail.messages.get_session_failed')
        });
      }
    });

    unsubscribeMutation = this.$store.subscribe((mutation: MutationPayload) => {
      switch (mutation.type) {
        case 'uiStore/setSnackbar': {
          const { color, action } = mutation.payload;
          if (color === 'success' && (action === 'CREATE_SESSION_OK' || action === 'EDIT_SESSION_OK')) {
            this.resetForm();
            this.$router.push({
              name: ROUTE_NAME.SALE_LIST
            });
          }
          break;
        }
        case 'sessionStore/setEntity': {
          if (this.session.mode === 'edit') {
            this.form.name = this.session.entity.name;
            this.form.postUrl = this.session.entity.postURL;
            this.form.fileUrl = this.session.entity.importFileURL;
            this.form.expiredAt = moment(this.session.entity.expiredAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD');
            this.form.shippingFees = this.session.entity.shippingFees;
            this.originalShippingFee = JSON.parse(JSON.stringify(this.session.entity.shippingFees));
            this.form.currency = this.session.entity.currency.toUpperCase();
          }
          break;
        }
      }
    });
  },
  mounted() {
    this.mode = this.session.mode;
  },
  beforeDestroy() {
    if (unsubscribeAction) unsubscribeAction();
    if (unsubscribeMutation) unsubscribeMutation();
  },
  methods: {
    // validations
    feeErr(index: string) {
      const errs: string[] = [];
      if (this.$v.form.shippingFees && this.$v.form.shippingFees.$each) {
        if (this.$v.form.shippingFees.$each[index].delete) return;
        if (!this.$v.form.shippingFees.$each[index].fee.required) {
          errs.push(this.$t('session_module.session_detail.errors.fee_required'));
        }
        if (!this.$v.form.shippingFees.$each[index].fee.decimal) {
          errs.push(this.$t('session_module.session_detail.errors.numbers_only'));
        }
      }
      return errs;
    },
    feeNameErr(index: string) {
      const errs: string[] = [];
      if (this.$v.form.shippingFees && this.$v.form.shippingFees.$each) {
        if (!this.$v.form.shippingFees.$each[index].name.required) {
          errs.push(this.$t('session_module.session_detail.errors.name_required'));
        }
      }
      return errs;
    },
    // methods & events
    resetForm() {
      if (this.$v) this.$v.$reset();
      this.form.expiredAt = '';
      this.form.name = '';
      this.form.price = '';
      this.form.inventory = '';
      this.form.fileUrl = '';
      this.form.postUrl = '';
      this.form.shippingTemplateId = '';
      this.fileUrlHint = '';
      this.form.uploadField = null;
    },
    onUploadClick() {
      const element = this.$refs.uploadInput as HTMLElement;
      element.blur();
    },
    onUploadChange(files: File) {
      if (!files) {
        this.form.fileUrl = '';
        return;
      }  else if (!allowedTypes.some((x: string) => x === mime.lookup(files.name))) {
        alert(this.$t('session_module.session_detail.errors.invalid_session_file_format'));
        this.form.uploadField = null;
        this.form.reuploadField = null;
        (this.$refs.uploadInput as any).reset();
        (this.$refs.reuploadInput as any).reset();
        return;
      } else {
        this.$store.dispatch('utilStore/uploadFile', { files: [files] });
      }
    },
    onUploadClearClick() {
      this.fileUrlHint = '';
    },
    onAdd() {
      this.resetForm();
    },
    onSave() {
      this.$v.form.$touch();
      if (!this.$v.form.name.$invalid && !this.$v.form.shippingTemplateId.$invalid && !this.$v.form.uploadField.$invalid && !this.$v.form.postUrl.$invalid && !this.$v.form.expiredAt.$invalid && !this.$v.form.currency.$invalid) {
        this.form.shippingFees = this.shipping.list.find((s: ShippingTemplate) => s.id === this.form.shippingTemplateId).regions;
        this.$store.dispatch('sessionStore/createSessionAction', this.form);
      } else {
        alert(this.$t('session_module.session_detail.errors.all_fields_required'));
      }
    },
    onEdit() {
      this.$v.form.$touch();

      if (!this.$v.form.name.$invalid && !this.$v.form.expiredAt.$invalid && !this.$v.form.postUrl.$invalid && !this.$v.form.shippingFees.$invalid && !this.$v.form.currency.$invalid) {
        const newShippingFee = JSON.parse(JSON.stringify(this.form.shippingFees)) as ShippingFee[];
        console.log(newShippingFee);
        if (!newShippingFee || !newShippingFee.length) {
          alert(this.$t('session_module.session_detail.errors.need_one_shipping_method'));
          return;
        }
        this.originalShippingFee.forEach((osf: ShippingFee) => {
          // push the deleted shippingFee to requestBody (newShippingFee) as backend needs { deleted: true } for deleted shippingFee
          if (this.form.shippingFees.findIndex((fsf) => fsf.id === osf.id) === -1) {
            newShippingFee.push({
              id: osf.id,
              name: osf.name,
              fee: osf.fee,
              delete: true,
            });
          }
        });
        this.form.shippingFees = newShippingFee;
        this.form.shippingFees.forEach((sf) => {
          if (sf.id === '' || sf.id === null) delete sf.id;
          if (sf.delete === false) delete sf.delete;
        });

        const body = { ...this.form, id: this.session.entity.id };
        this.$store.dispatch('sessionStore/editSessionAction', body);
      } else {
        alert(this.$t('session_module.session_detail.errors.all_fields_required'));
      }
    },
    onRemove(index: number) {
      const removed = this.form.shippingFees[index];
      removed.delete = true;
      this.form.shippingFees.splice(index, 1);
    },
    onAddArea() {
      this.form.shippingFees.push({
        id: '',
        name: '',
        fee: 0,
        delete: false
      });
    },
    notAllowToCreateSession(): string {
      if (!this.userStore.tenant.stripeAccountPaymentEnabled) return 'stripeAccountPaymentEnabled';
      else if (!this.userStore.tenant.stripeAccount) return 'stripeAccount';
      else return '';
    },
    ...mapActions('utilStore', ['uploadFile']),
  },

});
