
import Vue from 'vue';
import { required, decimal } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

let unsubscribeMutation: () => void;

export default Vue.extend({
  data: () => ({
    name: '',
    regions: [
      {
        name: '',
        fee: 0,
      },
    ],
  }),
  validations: {
    name: { required },
    regions: {
      $each: {
        name: { required },
        fee: { required, decimal, }
      }
    }
  },
  computed: {
    defaultNameErr() {
      const errs: string[] = [];
      if (this.$v.regions && this.$v.regions.$each) {
        if (!this.$v.name.required) {
          errs.push(this.$t('settings_module.shipping_template.errors.template_name_required').toString());
        }
      }
      return errs;
    },
    dialogTitle(): string {
      return `${this.shipping.dialog.mode === 'edit' ? this.$t('settings_module.shipping_template.edit') : this.$t('settings_module.shipping_template.new').toString()}`;
    },
    dialogActionButton(): string {
      return `${this.shipping.dialog.mode === 'edit' ? this.$t('general_module.save') : this.$t('general_module.create')}`;
    },
    ...mapGetters('settingStore', ['shipping'])
  },
  created() {
    unsubscribeMutation = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'settingStore/setShippingTemplate': {
          this.name = this.shipping.entity.name;
          this.regions = this.shipping.entity.regions;
          break;
        }
        case 'uiStore/setSnackbar': {
          const { color } = mutation.payload;
          if (color === 'success') this.onCancel();
          break;
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeMutation) unsubscribeMutation();
  },
  methods: {
    // validations
    feeErr(index: string) {
      const errs: string[] = [];
      if (this.$v.regions && this.$v.regions.$each) {
        if (!this.$v.regions.$each[index].fee.required) {
          errs.push(this.$t('settings_module.shipping_template.errors.fee_required').toString());
        }
        if (!this.$v.regions.$each[index].fee.decimal) {
          errs.push(this.$t('settings_module.shipping_template.errors.numbers_only').toString());
        }
      }
      return errs;
    },
    nameErr(index: string) {
      const errs: string[] = [];
      if (this.$v.regions && this.$v.regions.$each) {
        if (!this.$v.regions.$each[index].name.required) {
          errs.push(this.$t('settings_module.shipping_template.errors.name_required').toString());
        }
      }
      return errs;
    },
    // events
    addArea() {
      this.regions.push({
        name: '',
        fee: 0,
      });
    },
    onRemove(i: number) {
      if (this.regions.length === 1) return;
      this.regions.splice(i, 1);
    },
    onSave() {
      this.$v.$touch();
      if (!this.regions.length) {
        alert(this.$t('settings_module.shipping_template.errors.at_least_one_shipping_method'));
        return;
      }
      if (this.$v.$invalid) return;
      const data = {
        id: '',
        regions: this.regions,
        name: this.name
      };
      if (this.shipping.dialog.mode === 'create') {
        this.$store.dispatch('settingStore/createShippingTemplate', data);
      } else if (this.shipping.dialog.mode === 'edit') {
        data.id = this.shipping.entity.id;
        this.$store.dispatch('settingStore/updateShippingTemplate', data);
      }
    },
    reset() {
      this.name = '';
      this.regions = [{
        name: '',
        fee: 0
      }];
    },
    onAdd() {
      this.reset();
      this.$store.dispatch('settingStore/setShippingTemplateDialog', {
        show: true,
        mode: 'create'
      });
    },
    onCancel() {
      this.$store.dispatch('settingStore/setShippingTemplateDialog', {
        show: false,
        mode: 'create'
      });
      this.reset();
    }
  },
});
