import { Cart, CartItem, DeliveryDetail } from '@/store/cart-store';
import axios from 'axios';

async function getCart(cartId: string): Promise<Cart> {
  try {
    const response = await axios.get<Cart>(`${process.env.VUE_APP_API_HOST}/cart/${cartId}`);
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function putCartItems(cartId: string, items: CartItem[]): Promise<void> {
  try {
    const itemsBody: any = {};
    items.forEach((i: CartItem) => {
      itemsBody[i.itemId] = i.quantity;
    });
    await axios.put(`${process.env.VUE_APP_API_HOST}/cart/${cartId}/item`, itemsBody);
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function patchCart(cartId: string, shippingFeeId: string, deliverTo: DeliveryDetail): Promise<void> {
  try {
    const body = {
      shippingFeeId,
      deliverTo
    };
    await axios.patch<string>(`${process.env.VUE_APP_API_HOST}/cart/${cartId}`, body);
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

async function getIsPaidCart(cartId: string): Promise<boolean> {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_HOST}/cart/${cartId}/isPaid`);
    return response.data === 'true';
  } catch (err) {
    console.error(err);
    throw(err);
  }
}

export {
  getCart,
  patchCart,
  putCartItems,
  getIsPaidCart
};
