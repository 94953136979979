
import Vue from 'vue';

export default Vue.extend({
  name: 'app',
  components: {
  },
  data() {
    return {
      loading: false
    };
  },
  created() {
    this.$store.dispatch('uiStore/resetSnackbar', null, { root: true });
    this.$store.dispatch('uiStore/resetConfirmDialog', null, { root: true });
  },
  methods: {
  },
});
