import Vue from 'vue';
import Vuex, { ActionTree } from 'vuex';
import authStore, { AuthState } from './auth-store';
import uiStore, { UiState } from './ui-store';
import sampleStore from './sample-store';
import utilStore, { UtilState } from './util-store';
import sessionStore, { SessionState } from './session-store';
import userStore, { UserState } from './user-store';
import createPersistedState from 'vuex-persistedstate';
import settingStore, { SettingState } from './setting-store';
import cartStore, { CartState } from './cart-store';
import orderStore, { OrderState } from './order-store';
import generalStore, { GeneralState } from './general-store';

Vue.use(Vuex);

// global action to reset all state
const actions: ActionTree<RootState, RootState> = {
  resetState({ dispatch }) {
    // dispatch('userStore/resetUserState', null, { root: true });
    dispatch('authStore/resetAuthState', null, { root: true });
    dispatch('settingStore/resetSettingState', null, { root: true });
    dispatch('orderStore/resetOrderState', null, { root: true });
    // reset ui state
    dispatch('uiStore/resetConfirmDialog', null, { root: true });
    dispatch('uiStore/resetSnackbar', null, { root: true });
  }
};

export default new Vuex.Store({
  modules: {
    authStore,
    uiStore,
    sampleState: sampleStore,
    utilStore,
    sessionStore,
    userStore,
    settingStore,
    cartStore,
    orderStore,
    generalStore,
  },
  plugins: [createPersistedState()],
  actions
});

export interface RootState {
  authStore: AuthState;
  userStore: UserState;
  sessionStore: SessionState;
  uiStore: UiState;
  settingStore: SettingState;
  cartStore: CartState;
  utilStore: UtilState;
  orderStore: OrderState;
  generalStore: GeneralState;
}
