
import Vue from 'vue';
import ConfirmDialogComponent from '@/components/shared/dialog/confirm-dialog-component.vue';
import InfoDialog from '@/components/shared/dialog/info-dialog.vue';
import SnackbarComponent from '@/components/shared/snackbar/snackbar-component.vue';
import PageSelectionComponent from '@/components/dashboard/page-selection-component.vue';
import LanguageSelectionComponent from '@/components/dashboard/language-selection-component.vue';
import ThemeSelectionComponent from '@/components/dashboard/theme-selection-component.vue';
import { ActionPayload, mapActions, mapGetters, MutationPayload } from 'vuex';
import SelectPageDialogComponent from '@/components/dashboard/select-page-dialog-component.vue';
import { RootState } from '@/store';
import { linkStripeAccount } from '@/services/user-service';
import Alert from '@/components/dashboard/alert.vue';
import { ALERT_NAME } from '@/store/ui-store';
import { ROUTE_NAME } from '@/router';

let unsubscribeAction: () => void;
let unsubscribeMutation: () => void;

export default Vue.extend({
  name: 'dashboard-view',
  components: {
    SnackbarComponent,
    ConfirmDialogComponent,
    InfoDialog,
    PageSelectionComponent,
    LanguageSelectionComponent,
    ThemeSelectionComponent,
    SelectPageDialogComponent,
    Alert,
  },
  data() {
    return {
      listIndex: 0,
      drawer: true,
      mini: true,
      items: [
        // {
        //   title: 'main.drawer_dashboard',
        //   icon: 'mdi-view-dashboard-outline',
        //   link: '/dashboard/home',
        // },
        {
          title: 'main.drawer.sale',
          icon: 'mdi-video-plus-outline',
          link: '/dashboard/sales',
        },
        {
          title: 'main.drawer.order',
          icon: 'mdi-forum-outline',
          link: '/dashboard/orders',
        },
        {
          title: 'main.drawer.settings',
          icon: 'mdi-cog-outline',
          link: '/dashboard/settings',
          items: [{
            title: 'Shipping',
            icon: 'mdi-truck',
            link: '/dashboard/settings/shipping'
          }]
        },
      ],
      name: '',
      premium: '',
      linkStripeLoading: false,
      subscribeLoading: false,
      alerts: [] as any,
      profileImgUrl: ''
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    ...mapGetters('uiStore', ['confirmDialog', 'alertHistory']),
    ...mapGetters('userStore', ['userStore', 'tenant']),
    ...mapGetters('authStore', ['token']),
  },
  watch: {
    '$route.name' (val: ROUTE_NAME) {
      // assign correct selected menu
      this.assignActiveMenu(val);
    },
  },
  created() {
    if (this.userStore.selectedPage) {
      this.name = this.userStore.selectedPage.name;
    }
    this.$store.dispatch('authStore/setAuthenticating', { loading: false });
    this.$store.dispatch('authStore/setVerifying', { loading: false });
    this.$store.dispatch('userStore/getTenant');

    // if has query.sl then show success linked info dialog
    if (this.$route.query.sl) {
      this.$store.dispatch('uiStore/setInfoDialogAction', {
        from: 'dashboard-success-linked',
        title: this.$t('general_module.dialog.title_information'),
        text: this.$t('main.dialog.linked_stripe_msg'),
      });
      const query = Object.assign({}, this.$route.query);
      delete query.sl;
      this.$router.replace({ query });
    }

    unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
      if (action.type === 'uiStore/confirmDialogOk') {
        if (this.confirmDialog.from === this.$options.name) {
          localStorage.setItem('bb_auths', '');
          this.$store.dispatch('resetState');
          window.location.href = process.env.VUE_APP_LOGOUT_URL;
        }
      }
    });

    unsubscribeMutation = this.$store.subscribe((mutation: MutationPayload, state: RootState) => {
      if (mutation.type === 'userStore/setSelectedPage' && state.userStore.selectedPage) {
        this.name = state.userStore.selectedPage.name;
      }
      if (mutation.type === 'userStore/setTenant') {
        if (!this.tenant.stripeAccount || !this.tenant.stripeAccountPaymentEnabled) {
          if ((this.alertHistory as string[]).indexOf(ALERT_NAME.STRIPE_NOT_LINKED) >= 0) return;
          this.$store.dispatch('uiStore/insertAlertAction', {
            name: ALERT_NAME.STRIPE_NOT_LINKED,
            show: true,
            type: 'warn',
            color: 'orange',
            text: 'main.alert.setup_stripe',
            showSettings: true
          });
        } else if (this.tenant.stripeAccount && this.tenant.stripeAccountPaymentEnabled) {
          this.$store.dispatch('uiStore/removeAlertAction', {
            name: ALERT_NAME.STRIPE_NOT_LINKED
          });
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeAction) unsubscribeAction();
    if (unsubscribeMutation) unsubscribeMutation();
  },
  mounted() {
    // assign correct selected menu
    this.assignActiveMenu(this.$route.name);

    if (this.$route.name === ROUTE_NAME.DASHBOARD) {
      this.$router.push({ name: ROUTE_NAME.SALE_LIST });
    }

    fetch(this.userStore.tenant.picture).then((res: Response) => {
      if (res.status === 404) {
        this.profileImgUrl = require('@/assets/user.png');
      } else {
        this.profileImgUrl = this.userStore.tenant.picture;
      }
    }).catch(() => {
      this.profileImgUrl = require('@/assets/user.png');
    }).finally(() => {
      return this.profileImgUrl;
    });
  },
  methods: {        
    onFaqClick() {
      if (this.$route.name !== ROUTE_NAME.FAQ_MAIN) {
        this.$router.push({
          name: ROUTE_NAME.FAQ_MAIN
        });
      }
    },
    onBurgerClick() {
      this.drawer = !this.drawer;
      this.mini = !this.mini;
    },
    onLogout() {
      this.$store.dispatch('uiStore/setConfirmDialog', {
        from: this.$options.name,
        title: this.$t('main.drawer.logout'),
        text: this.$t('main.drawer.logout_msg'),
      });
    },
    onLinkStripe() {
      this.linkStripeLoading = true;
      linkStripeAccount(this.token).then((url) => {
        if (url) window.open(url, '_blank');
        else throw Error('Link Stripe URL missing');
      }).catch((err) => {
        console.error(err);
        this.$store.dispatch('uiStore/setSnackbar', {
          color: 'error',
          action: 'tenant_link_stripe',
          text: 'Unable to link Stripe account, please try again later.'
        });
      }).finally(() => {
        this.linkStripeLoading = false;
      });
    },
    onMenuItemClick(location: string) {
      if (this.$route.fullPath !== location) {
        this.$router.push(location);
      }
    },
    assignActiveMenu(name: ROUTE_NAME) {
      switch (name) {
        case ROUTE_NAME.SALES:
        case ROUTE_NAME.SALE_CREATE:
        case ROUTE_NAME.SALE_EDIT:
        case ROUTE_NAME.SALE_LIST:
          this.listIndex = 0;
          break;
        case ROUTE_NAME.ORDERS:
          this.listIndex = 1;
          break;
        case ROUTE_NAME.SETTINGS:
        case ROUTE_NAME.SETTINGS_MAIN:
        case ROUTE_NAME.SETTINGS_SHIPPING_TEMPLATES:
        case ROUTE_NAME.SETTINGS_SUBSCRIPTION:
        case ROUTE_NAME.SETTINGS_STRIPE_PAYMENT:
          this.listIndex = 2;
          break;
        default: this.listIndex = -1; break;
      }
    },
    ...mapActions('authStore', ['setAuthenticating', 'setVerifying']),
  },
});
