
import { TenantPage } from '@/store/user-store';
import axios from 'axios';
import Vue from 'vue';
import { MutationPayload, mapGetters } from 'vuex';

let unsubscribeMutation: () => void;

export default Vue.extend({
  name: 'username-input-dialog',
  data: () => ({
    dialog: false,
    saving: false,
    pageData: [{
      id: '',
      name: '',
      username: '',
      patch: false
    }],
  }),
  computed: {
    ...mapGetters('authStore', ['token']),
    ...mapGetters('userStore', ['pages', 'selectedPage']),
  },
  created() {
    unsubscribeMutation = this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'userStore/setTenantPage') {
        this.fetchPageData();
      }
    })
  },
  beforeDestroy() {
    if (unsubscribeMutation) unsubscribeMutation();
  },
  mounted() {
    this.fetchPageData();
  },
  methods: {
    // events
    async onSave() {
      let counter = 0;
      this.pageData.forEach(async (pd) => {
        if (!pd.patch) return;
        this.saving = true;
        try {
          const response = await axios.patch(`${process.env.VUE_APP_API_HOST}/page/${pd.id}`, {
            name: pd.name,
            username: pd.username
          }, {
            headers: { Authorization: this.token }
          });
          if (response) counter++;
        } catch (err) {
          console.error(err);
        }

        const total = this.pageData.reduce((total, p) => {
          if (p.patch) total++; 
          return total;
        }, 0)

        if (counter === total) {
          this.saving = false;
          this.dialog = false;
          this.$store.dispatch('userStore/getTenant');
        }
      });
    },
    onClose() {
      this.dialog = false;
    },
    onUsernameInput(pageId: string) {
      this.pageData.find(pd => pd.id === pageId)!.patch = true;
    },
    // methods
    fetchPageData() {
      this.pageData = this.pages.map((p: TenantPage) => ({
        id: p.id,
        name: p.name,
        username: p.username,
        patch: !p.username
      }));
    }
  }
});
