
import { ROUTE_NAME } from '@/router';
import Vue from 'vue';
// import cartComponent from '@/components/cart/cart-component.vue';

export default Vue.extend({
  name: 'cart-view',
  // components: { cartComponent },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    renderTitle() {
      if (this.$route.name === ROUTE_NAME.CART_PAYMENT) return 'BillerBee Payment';
      else return 'BillerBee Shopping Cart';
    }
  },
  beforeCreate() {
    this.$store.dispatch('cartStore/resetCartState');
  }
});
