
import Vue from 'vue';

export default Vue.extend({
  name: 'language-selection-component',
  data() {
    return {
      selected: localStorage.getItem('bb_lang') || 'EN',
      langs: [
        { name: 'EN', val: 'en' },
        { name: 'BM', val: 'bm' },
        { name: 'CN', val: 'cn' }
      ]
    };
  },
  methods: {
    langClicked(val: string) {
      this.selected = val;
      this.$i18n.locale = val;
      this.$root.$i18n.locale = val;
      localStorage.setItem('bb_lang', val);
    }
  }
});
