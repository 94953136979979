import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import i18n from './i18n';
import Vuelidate from 'vuelidate';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import imageZoom from 'vue-image-zoomer';
import VueExpandableImage from 'vue-expandable-image';


// datetime filter
Vue.filter('formatDatetime', (date: string | Date) => {
  if (date && moment(date).isValid()) {
    return moment(date).format('YYYY-MM-DD hh:mm:ss A');
  } else {
    // a
  }
});

// font awesome
library.add(faStripe);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(VueToast);
Vue.use(Vuelidate);

// vue image zoomer
Vue.component('vue-image-zoomer', imageZoom);
Vue.use(VueExpandableImage);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
