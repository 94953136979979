
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { AuthResponse } from '@/models/auths';
import { themes } from '@/models/shared/themes';
import { verifyToken } from '@/services/auth-service';
import { ROUTE_NAME } from '@/router';

export default Vue.extend({
  name: 'login-component',
  data() {
    return {
      loginLoading: false,
      themes,
      loginErr: '',
    };
  },
  computed: {
    ...mapGetters('authStore', ['authenticating', 'verifying']),
  },
  watch: {
    // "userStore."
  },
  async mounted() {
    await this.auth();
    this.detectTheme();
    if (this.$route.query?.auto) {
      this.loginClicked();
    }
  },
  methods: {
    loginClicked() {
      this.loginLoading = true;
      window.location.href = process.env.VUE_APP_LOGIN_URL;
      // if (!localStorage.getItem('access_token')) {
      //   window.location.href = process.env.VUE_APP_LOGIN_URL;
      // }
      // this.loginLoading = false;
    },
    detectTheme() {
      // detect theme from existing cookie (if any)
      const primary = localStorage.getItem('bb_theme');
      if (!primary) return;

      // handle color theme
      if (this.themes.light.find((t) => t.primary === primary)) {
        this.setTheme(
          this.themes.light.find((t) => t.primary === primary),
          'light'
        );
      } else {
        this.setTheme(
          this.themes.dark.find((t) => t.primary === primary),
          'dark'
        );
      }
    },
    setTheme(colors: any, theme: string) {
      if (theme === 'light') {
        Object.keys(colors).forEach((i) => {
          this.$vuetify.theme.dark = false;
          this.$vuetify.theme.themes.light[i] = colors[i];
        });
      } else {
        Object.keys(colors).forEach((i) => {
          this.$vuetify.theme.dark = true;
          this.$vuetify.theme.themes.dark[i] = colors[i];
        });
      }

      localStorage.setItem('bb_theme', colors.primary);
    },
    async auth() {
      const hash = this.$route.hash ? this.$route.hash.substr(1) : '';

      // if hash is found
      if (hash && hash.split('&')) {
        const params = hash.split('&');
        if (params.some((k: string) => k.split('=')[0] === 'id_token')) {
          // login success
          this.loginErr = '';
          this.$store.dispatch('authStore/setAuthenticating', {
            loading: true,
          });
          let auths: any = {};
          params.forEach((p: string) => {
            auths[p.split('=')[0]] = p.split('=')[1];
          });

          auths = auths as AuthResponse;
          localStorage.setItem('bb_auths', JSON.stringify(auths));
          this.$store.dispatch('authStore/setAuth');
          this.$store.dispatch('generalStore/getApplicationSettingsAction');
          this.$store.dispatch('uiStore/clearAlertAction');
          this.$store.dispatch('uiStore/clearAlertHistoryAction');
          this.$router.push({ name: ROUTE_NAME.SALE_LIST });
        } else {
          // error handling
          this.loginErr =
            'Unable to login at this moment. Please try again later.';
          // console.log(this.$route);
          this.$router.push({ path: '' });
        }
      } else {
        this.$store.dispatch('authStore/setVerifying', { loading: true });

        // if auth is invalid then display login screen
        const auths = localStorage.getItem('bb_auths');
        if (!auths) {
          this.$store.dispatch('authStore/setVerifying', { loading: false });
          return;
        }

        // if token is invalid then display login screen
        const tokens = JSON.parse(auths as string);
        if (!tokens.access_token) {
          this.$store.dispatch('authStore/setVerifying', { loading: false });
          return;
        }

        // auto login from homepage if token in localStorage is valid
        try {
          const response = await verifyToken(tokens.access_token);
          if (response.status === 200) {
            this.$router.push({ name: ROUTE_NAME.SALE_LIST });
          }
        } catch (err: any) {
          if (err.response.status !== 401) {
            console.error(err.data);
          }
          this.$store.dispatch('authStore/setVerifying', { loading: false });
        }
      }
    },
    ...mapActions('authStore', ['setAuthenticating', 'setVerifying']),
    ...mapActions('userStore', ['getTenant']),
  },
});
