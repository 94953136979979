import axios from 'axios';

// async function getCurrencyPaymentMethod(): Promise<CurrencyPaymentMethod> {
//   try {
//     const response = await axios.get(`${process.env.VUE_APP_API_HOST}/currencyPaymentMethods`);
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     throw(err);
//   }

// }

async function getApplicationSettings(): Promise<ApplicationSettings> {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_HOST}/settings`);
    return response.data;
  } catch (err) {
    console.error(err);
    throw(err);
  }

}

export interface ApplicationSettings {
  currencyPaymentMethods: {
    [key: string]: string[];
  };
  minimumCheckoutAmount?: number;
}

export {
  getApplicationSettings
};
