import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css' ;


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: '#C3CED5',
        secondary: '#FF9909',
        accent: '#D4DDE2',
        success: '#007E33',
        info: '#0099CC',
        warning: '#FF8800',
        error: '#CC0000',
        contrast: '#212121',
        background: '#EBEBEB'
      },
      dark: {
        primary: '#344955',
        secondary: '#ff9500',
        accent: '#232f34',
        success: '#007E33',
        info: '#0099CC',
        warning: '#FF8800',
        error: '#CC0000',
        contrast: '#212121',
        background: '#292929'
      }
    }
  },
  icons: { iconfont: 'mdi' || 'fa' }
});
