
import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'stripe-payment',
  computed: {
    ...mapGetters('userStore', ['userStore']),
    ...mapGetters('authStore', ['token']),
    ...mapGetters('uiStore', ['alerts']),
  },
  methods: {
    // events
  }
});
