
import Vue from 'vue';
import countriesData from '@/models/location/countries.json';
import { required } from 'vuelidate/lib/validators';
import { CountryCode, isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

export const phoneNumberValidator = (countryCode: CountryCode) => (phoneNumber: string) =>  isPossiblePhoneNumber(phoneNumber, countryCode) || isValidPhoneNumber(phoneNumber, countryCode);

function initData() {
  return {
    dialog: false,
    shippingDetails: {
      name: '',
      contactNo: '',
      address: '',
      postcode: '',
      city: '',
      state: '',
      country: ''
    },
    countries: countriesData
  };
}

export default Vue.extend({
  name: 'cart-address-dialog-component',
  props: {
    countryCode: {
      type: String,
      default: ''
    }
  },
  validations() {
    return {
      shippingDetails: {
        name: { required },
        contactNo: { required, validPhoneNumber: phoneNumberValidator(this.shippingDetails.country as CountryCode) },
        address: { required },
        postcode: { required },
        city: { required },
        state: { required },
        country: { required },
      }
    };
  },
  data: () => {
    return initData();
  },
  computed: {
    nameErr() {
      const errs: string[] = [];
      if (this.$v.shippingDetails.name) {
        if (!this.$v.shippingDetails.name.$dirty) return errs;
        if (!this.$v.shippingDetails.name.required) errs.push('Name is required.');
      }
      return errs;
    },
    contactNoErr() {
      const errs: string[] = [];
      if (this.$v.shippingDetails.contactNo) {
        if (!this.$v.shippingDetails.contactNo.$dirty) return errs;
        if (!this.$v.shippingDetails.contactNo.required) errs.push('Contact is required.');
        if (!this.$v.shippingDetails.contactNo.validPhoneNumber) errs.push('Incorrect contact number format.');
      }
      return errs;
    },
    addressErr() {
      const errs: string[] = [];
      if (this.$v.shippingDetails.address) {
        if (!this.$v.shippingDetails.address.$dirty) return errs;
        if (!this.$v.shippingDetails.address.required) errs.push('Address is required.');
      }
      return errs;
    },
    postcodeErr() {
      const errs: string[] = [];
      if (this.$v.shippingDetails.postcode) {
        if (!this.$v.shippingDetails.postcode.$dirty) return errs;
        if (!this.$v.shippingDetails.postcode.required) errs.push('Postcode is required.');
      }
      return errs;
    },
    cityErr() {
      const errs: string[] = [];
      if (this.$v.shippingDetails.city) {
        if (!this.$v.shippingDetails.city.$dirty) return errs;
        if (!this.$v.shippingDetails.city.required) errs.push('City is required.');
      }
      return errs;
    },
    stateErr() {
      const errs: string[] = [];
      if (this.$v.shippingDetails.state) {
        if (!this.$v.shippingDetails.state.$dirty) return errs;
        if (!this.$v.shippingDetails.state.required) errs.push('State is required.');
      }
      return errs;
    },
    countryErr() {
      const errs: string[] = [];
      if (this.$v.shippingDetails.country) {
        if (!this.$v.shippingDetails.country.$dirty) return errs;
        if (!this.$v.shippingDetails.country.required) errs.push('Country is required.');
      }
      return errs;
    }
  },
  mounted() {
    this.setDefaultCountryCode();
  },
  methods: {
    setDefaultCountryCode() {
      if (this.countries.findIndex((x) => x.code === this.countryCode) > 0) {
        this.shippingDetails.country = this.countryCode;
      }
    },
    saveAddress() {
      this.$v.shippingDetails.$touch();
      if (this.$v.shippingDetails.$invalid) return;
      if (isPossiblePhoneNumber(this.shippingDetails.contactNo, this.shippingDetails.country as CountryCode) || isValidPhoneNumber(this.shippingDetails.contactNo, this.shippingDetails.country as CountryCode)) {
        // console.log('correct');
      }
      const cartAddress = localStorage.getItem('cart_address');
      const addrs = JSON.parse(cartAddress || '[]');
      this.shippingDetails.name = this.shippingDetails.name.trim();
      this.shippingDetails.contactNo = this.shippingDetails.contactNo.trim();
      this.shippingDetails.address = this.shippingDetails.address.trim();
      this.shippingDetails.postcode = this.shippingDetails.postcode.trim();
      this.shippingDetails.city = this.shippingDetails.city.trim();
      this.shippingDetails.state = this.shippingDetails.state.trim();
      this.shippingDetails.country = this.countries.find((c) => c.code === this.shippingDetails.country)?.name || '';
      addrs.push(this.shippingDetails);
      localStorage.setItem('cart_address', JSON.stringify(addrs));
      Object.assign(this.$data, initData());
      this.$v.shippingDetails.$reset();
      this.setDefaultCountryCode();
      this.$emit('addressSaved', true);
    },
    onCancel() {
      this.dialog = !this.dialog;
      Object.assign(this.$data, initData());
      this.$v.shippingDetails.$reset();
      this.setDefaultCountryCode();
    }
  }
});
