
import axios from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'account-delete-dialog',
  data: () => ({
    dialog: false,
    deleteBtnText: '',
    showTooltip: false,
    deleting: false
  }),
  computed: {
    ...mapGetters('authStore', ['token']),
  },
  created() {
    this.deleteBtnText = 'delete';
  },
  methods: {
    async onDelete() {
      if (this.showTooltip) {
        this.deleting = true;
        try {
          // api call to delete profile
          await axios.delete(`${process.env.VUE_APP_API_HOST}/tenant/me`, {
            headers: { Authorization: this.token }
          });
          window.location.href = process.env.VUE_APP_BILLERBEE_DOMAIN;
        } catch (err) {
          console.error(err);
        } finally {
          this.deleting = false;
          this.onCancel();
        }

        return;
      }

      this.showTooltip = true;
      this.deleteBtnText = 'confirm';
    },
    onCancel() {
      this.dialog = false;
      this.showTooltip = false;
      this.deleteBtnText = 'delete';
    }
  }
});
