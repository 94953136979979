
import Vue from 'vue';

export default Vue.extend({
  name: 'home-drawer-view',
  props: {
    drawer: Boolean
  },
  data() {
    return {
      drawerData: this.drawer
    };
  },
  watch: {
    drawer(newValue: any) {
      this.drawerData = newValue;
      this.$emit('drawerToggled', this.drawerData);
    }
  },
  methods: {
    closeMenu() {
      this.drawerData = false;
      this.$emit('drawerToggled', this.drawerData);
    },
    drawerInput(model: boolean) {
      this.drawerData = model;
      this.$emit('drawerToggled', this.drawerData);
    },
    scrollToTop() {
      this.closeMenu();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollTo(id: string) {
      this.closeMenu();
      const element = document.getElementById(id) as HTMLElement;
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    },
  }
});
