import { activateSession, deactivateSession, deleteSession, getSession, getSessions, patchSession, postSessionShippingFees, putSession } from '@/services/session-service';
import { RootState } from '.';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { TenantPage } from './user-store';

const namespaced = true;
const defaultSessionState = (): SessionState => {
  return {
    session: {
      list: [],
      listLoading: false,
      entity: null as any,
      entityLoading: false,
      saving: false,
      savedId: '',
      dialog: {
        show: false,
        mode: 'create'
      },
      deleting: false,
      mode: 'create',
      activation: {
        loading: false
      }
    },
    price: {
      list: [],
      listLoading: false
    },
    inventory: {
      list: [],
      listLoading: false
    }
  };
};
const state = defaultSessionState();

const getters: GetterTree<SessionState, RootState> = {
  sessionStore: (state): SessionState => state,
  session: (state) => state.session,
};

const actions: ActionTree<SessionState, RootState> = {
  async getSessionListAction({ commit, dispatch, rootGetters, rootState }) {
    commit('sessionListLoading', true);
    try {
      const userStore = rootGetters['userStore/userStore'];
      const resp = await getSessions(userStore.selectedPage.id, rootState.authStore.token);

      if (resp) {
        commit('sessionList', resp);
      } else {
        throw new Error('API failure: unable to get session list.');
      }
    } catch (err) {
      console.error(err);
      commit('sessionList', []);
      dispatch('uiStore/setSnackbar', {
        color: 'error',
        text: 'Unable to retrieve session list, please try again later.'
      }, { root: true });
    } finally {
      commit('sessionListLoading', false);
    }
  },

  GET_SESSION_LIST_OK() {
    // get session list success
  },

  GET_SESSION_LIST_FAILED() {
    // get session list failed
  },

  async getSessionAction({ commit, dispatch, rootState }, params: { sessionId: string }) {
    commit('entityLoading', true);
    try {
      const resp = await getSession(params.sessionId, rootState.authStore.token);

      if (resp) {
        commit('setEntity', resp);
      } else throw new Error('API failure: unable to get session.');
    } catch (err) {
      console.error(err);
      dispatch('GET_SESSION_FAILED');
    } finally {
      commit('entityLoading', false);
    }
  },

  GET_SESSION_OK() {
    // get session success
  },

  GET_SESSION_FAILED() {
    // get session failed
  },

  async createSessionAction({ commit, dispatch, rootGetters, rootState }, params: any) {
    commit('sessionSaving', true);
    try {
      const userStore = rootGetters['userStore/userStore'];
      if (!userStore.selectedPage.id) {
        dispatch('CREATE_SESSION_FAILED_PAGEID_MISSING');
        return;
      }
      params = { pageId: userStore.selectedPage.id, ...params };
      const sessionId = await putSession(params, rootState.authStore.token);
      if (sessionId) {
        dispatch('CREATE_SESSION_OK');
        dispatch('getSessionListAction');
        commit('sessionSaved', sessionId);
      } else throw new Error('API failure: unable to create session.');
    } catch (err: any) {
      console.error(err);
      dispatch('CREATE_SESSION_FAILED', {
        apiErrCode: err?.response?.status === 400 ? err.response.data.code : ''
      });
    } finally {
      commit('sessionSaving', false);
    }
  },

  CREATE_SESSION_OK() {
    // create session success
  },

  CREATE_SESSION_FAILED() {
    // create session failed
  },

  CREATE_SESSION_FAILED_PAGEID_MISSING() {
    // create session failed
  },

  async editSessionAction({ commit, dispatch, rootState }, params: any) {
    commit('sessionSaving', true);
    try {
      const sessionId = await patchSession(params, rootState.authStore.token);
      const shippingFees = await postSessionShippingFees(params.id, params.shippingFees, rootState.authStore.token);

      if (!shippingFees || !shippingFees.length) {
        dispatch('EDIT_SESSION_SHIPPING_FAILED');
      }

      if (sessionId) {
        dispatch('EDIT_SESSION_OK');
        dispatch('getSessionListAction');
        commit('sessionSaved', sessionId);
      } else throw new Error('API failure: unable to edit session.');
    } catch (err: any) {
      console.error(err);
      dispatch('EDIT_SESSION_FAILED');
    } finally {
      commit('sessionSaving', false);
    }
  },

  EDIT_SESSION_OK() {
    // edit session success
  },

  EDIT_SESSION_SHIPPING_FAILED() {
    // edit session shipping failed
  },

  EDIT_SESSION_FAILED() {
    // edit session failed
  },

  async deleteSessionAction({ commit, dispatch, rootState }, params: any) {
    commit('sessionDeleting', true);
    try {
      const sessionId = await deleteSession(params.id, rootState.authStore.token);
      if (sessionId) {
        if (sessionId) {
          dispatch('DELETE_SESSION_OK', sessionId);
          dispatch('getSessionListAction');
        } else throw new Error('API failure: unable to delete session.');
      }
    } catch (err: any) {
      console.error(err);
      dispatch('DELETE_SESSION_FAILED');
    } finally {
      commit('sessionDeleting', false);
    }
  },

  DELETE_SESSION_OK() {
    // delete session success
  },

  DELETE_SESSION_FAILED() {
    // delete session failed
  },

  async activateSessionAction({ commit, dispatch, rootState }, params: { sessionId: string }) {
    commit('setActivationLoading', true);
    try {
      const sessionId = await activateSession(params.sessionId, rootState.authStore.token);
      if (sessionId) {
        dispatch('ACTIVATE_SESSION_OK', sessionId);
        dispatch('getSessionListAction');
      } else throw new Error('API failure: unable to activate session.');
    } catch (err: any) {
      console.error(err);
      dispatch('ACTIVATE_SESSION_FAILED', err?.message || err);
    } finally {
      commit('setActivationLoading', false);
    }
  },

  ACTIVATE_SESSION_OK() {
    // activate session success
  },

  ACTIVATE_SESSION_FAILED() {
    // activate session failed
  },

  async deactivateSessionAction({ commit, dispatch, rootState }, params: { sessionId: string }) {
    commit('setActivationLoading', true);
    try {
      const sessionId = await deactivateSession(params.sessionId, rootState.authStore.token);
      if (sessionId) {
        dispatch('DEACTIVATE_SESSION_OK', sessionId);
        dispatch('getSessionListAction');
      } else throw new Error('API failure: unable to deactivate session.');
    } catch (err: any) {
      console.log(err.response);
      dispatch('DEACTIVATE_SESSION_FAILED', {
        apiErrCode: err?.response?.status === 400 ? err.response.data.code : ''
      });
    } finally {
      commit('setActivationLoading', false);
    }
  },

  DEACTIVATE_SESSION_OK() {
    // deactivate session success
  },

  DEACTIVATE_SESSION_FAILED() {
    // deactivate session failed
  },

  setSessionDialog({ commit }, params) {
    if (params.show) commit('showSessionDialog', params.mode);
    else commit('hideSessionDialog', params.mode);
  },
  resetSessionDialog({ commit }) {
    commit('resetSessionDialog');
  },
  sessionDetailMode({ commit }, params: { mode: 'create' | 'edit' }) {
    commit('setSessionDetailMode', params.mode);
  }
};

const mutations: MutationTree<SessionState> = {
  sessionListLoading: (state, loading: boolean) => {
    state.session.listLoading = loading;
  },
  sessionList: (state, data) => {
    if (!data) data = [];
    state.session.list = data;
  },
  sessionSaving: (state, saving: boolean) => {
    state.session.saving = saving;
  },
  sessionSaved: (state, id: string) => {
    state.session.savedId = id;
  },
  showSessionDialog: (state, mode: 'create' | 'edit') => {
    state.session.dialog.show = true;
    state.session.dialog.mode = mode;
  },
  hideSessionDialog: (state) => {
    state.session.dialog.show = false;
  },
  resetSessionDialog: (state) => {
    state.session.entity = defaultSessionState().session.entity;
  },
  setEntity: (state, data: Session) => {
    state.session.entity = data;
  },
  entityLoading: (state, loading: boolean) => {
    state.session.entityLoading = loading;
  },
  sessionDeleting: (state, deleting: boolean) => {
    state.session.deleting = deleting;
  },
  setSessionDetailMode: (state, mode: 'create' | 'edit') => {
    state.session.mode = mode;
  },
  setActivationLoading: (state, loading: boolean) => {
    state.session.activation.loading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export interface SessionState {
  session: Sessions;
  price: Prices;
  inventory: Inventories;
}

export interface Sessions {
  list: Session[];
  listLoading: boolean;
  entity: Session;
  entityLoading: boolean;
  saving: boolean;
  savedId: string;
  dialog: {
    show: boolean;
    mode: 'create' | 'edit'
  };
  deleting: boolean;
  mode: 'create' | 'edit';
  activation: {
    loading: boolean;
  };
}

export interface Prices {
  list: any;
  listLoading: boolean;
}

export interface Inventories {
  list: any;
  listLoading: boolean;
}

export interface SessionItem {
  id: string;
  code: string;
  name: string;
  price: number;
  quantity: number;
}

export interface Session {
  id: string;
  name: string;
  pageId: string;
  postURL: string;
  items: SessionItem[];
  shippingFees: ShippingFee[];
  ignoreInventory: boolean;
  page: TenantPage;
  activated: boolean;
  currency: string;
}

export interface ShippingFee {
  id?: string;
  name: string;
  fee: number;
  delete?: boolean;
}
