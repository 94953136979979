
import Vue from 'vue';
import { ActionPayload, mapGetters, MutationPayload } from 'vuex';
import cartAddressDialogComponent from './cart-address-dialog-component.vue';
import { CartItem, DeliveryDetail } from '@/store/cart-store';
import { Session, SessionItem, ShippingFee } from '@/store/session-store';
import axios from 'axios';
import { ROUTE_NAME } from '@/router';
import { DataTableHeader } from 'vuetify';
import copyTextTooltip from '@/components/shared/copy-text-tooltip/copy-text-tooltip.vue';

let unsubscribeMutation: () => void;
let unsubscribeAction: () => void;

interface LocalStorageAddress {
  name: string;
  contactNo: string;
  address: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
}

export default Vue.extend({
  components: {
    cartAddressDialogComponent,
    copyTextTooltip,
    // StripeElementPayment
  },
  data: (): {
    summaryHeaders: DataTableHeader[];
    notFound: boolean;
    cartId: string;
    sessionId: string;
    addresses: LocalStorageAddress[];
    itemTotal: number;
    shippingTotal: number | string;
    total: number;
    shippingMethodModel: string;
    deliveryAddressModel: number;
    shippingFeeId: string;
    delivery: DeliveryDetail | null;
    countryCode: string;
    payLoading: boolean;
    increasedItemIndex: number;
    decreasedItemIndex: number;
    removedItemIndex: number;
    oldRadioShippingFeeId: string;
    newRadioShippingFeeId: string;
    oldRadioAddressIndex: number;
    newRadioAddressIndex: number;
    snapshotItem: ShippingFee | undefined;
    snapshotAddress: LocalStorageAddress | undefined;
  } => ({
    summaryHeaders: [
      { text: 'Code - Name', sortable: false, value: 'title' },
      { text: 'Unit price', sortable: false, value: 'price' },
      { text: 'Quantity', sortable: false, value: 'quantity', align: 'center' },
      { text: 'Item total', sortable: false, value: 'itemTotal', align: 'end' },
    ],
    notFound: false,
    cartId: '',
    sessionId: '',
    addresses: [],
    itemTotal: 0,
    shippingTotal: 0,
    total: 0,
    shippingMethodModel: '',
    deliveryAddressModel: -1,
    shippingFeeId: '',
    delivery: null,
    countryCode: '',
    payLoading: false,
    increasedItemIndex: -1,
    decreasedItemIndex: -1,
    removedItemIndex: -1,
    oldRadioShippingFeeId: '',
    newRadioShippingFeeId: '',
    oldRadioAddressIndex: -1,
    newRadioAddressIndex: -1,
    snapshotItem: undefined,
    snapshotAddress: undefined,
  }),
  computed: {
    ...mapGetters('cartStore', ['cart', 'cartLoading', 'cartItemsLoading', 'cartDeliveryLoading', 'cartShippingLoading', 'session']),
  },
  beforeCreate() {
    if (this.$route.params.cartId) {
      this.$store.dispatch('cartStore/getCartAction', {
        cartId: this.$route.params.cartId
      });
    }
  },
  created() {
    // check saved addresses
    this.cartId = this.$route.params.cartId;
    const cartAddress = localStorage.getItem('cart_address');
    this.addresses = JSON.parse(cartAddress || '[]');

    axios.get('https://ipapi.co/json/').then((res) => {
      if (res.data) {
        this.countryCode = res.data.country_code;
      }
    });

    // subscribe to mutations
    unsubscribeMutation = this.$store.subscribe((mutation: MutationPayload) => {
      switch (mutation.type) {
        case 'cartStore/setCartAndSession': {
          // assign selected shipping method radio button
          this.shippingMethodModel = this.cart.shippingFeeId;
          this.oldRadioShippingFeeId = this.shippingMethodModel;

          // assign value
          this.shippingFeeId = this.cart.shippingFeeId;
          this.delivery = this.cart.deliverTo;

          // assign selected address (if any)
          const addressIndex = this.addresses.findIndex((a: LocalStorageAddress) =>
            a.name === this.cart.deliverTo.name &&
            a.contactNo === this.cart.deliverTo.contactNo &&
            a.address === this.cart.deliverTo.address.address &&
            a.postcode === this.cart.deliverTo.address.postcode &&
            a.city === this.cart.deliverTo.address.city &&
            a.state === this.cart.deliverTo.address.state &&
            a.country === this.cart.deliverTo.address.country
          );

          if (addressIndex > -1) {
            this.deliveryAddressModel = addressIndex;
            this.oldRadioAddressIndex = this.deliveryAddressModel;

          }

          // set shipping total
          this.shippingTotal = (this.session as Session).shippingFees.find((f: ShippingFee) => f.id === this.cart.shippingFeeId)?.fee.toFixed(2) || 0;
          break;
        }
      }
    });

    unsubscribeAction = this.$store.subscribeAction((action: ActionPayload) => {
      switch (action.type) {
        case 'cartStore/GET_CART_FAILED': {
          this.notFound = true;
          break;
        }
        case 'cartStore/UPDATE_CART_OK': {
          if (this.snapshotItem) {
            this.shippingTotal = +this.snapshotItem?.fee || 0;
            this.shippingFeeId = this.snapshotItem.id!;
            this.snapshotItem = undefined;
            this.oldRadioShippingFeeId = '';
          }
          if (this.snapshotAddress) {
            const deliverTo = {
              name: this.snapshotAddress.name,
              contactNo: this.snapshotAddress.contactNo,
              address: {
                address: this.snapshotAddress.address,
                postcode: this.snapshotAddress.postcode,
                city: this.snapshotAddress.city,
                state: this.snapshotAddress.state,
                country: this.snapshotAddress.country
              }
            };
            this.delivery = deliverTo;
            this.snapshotAddress = undefined;
            this.oldRadioAddressIndex = -1;
          }
          break;
        }
        case 'cartStore/UPDATE_CART_FAILED': {
          if (this.oldRadioShippingFeeId) {
            this.shippingMethodModel = this.oldRadioShippingFeeId;
            this.$store.dispatch('uiStore/setSnackbar', {
              color: 'error',
              action: 'UPDATE_CART_ITEM_FAILED',
              text: 'Unable to change shipping method. Please try again later.'
            });
            this.snapshotItem = undefined;
            this.oldRadioShippingFeeId = '';
          }
          if (this.oldRadioAddressIndex > -1) {
            this.deliveryAddressModel = this.oldRadioAddressIndex;
            this.$store.dispatch('uiStore/setSnackbar', {
              color: 'error',
              action: 'UPDATE_CART_ITEM_FAILED',
              text: 'Unable to change delivery address. Please try again later.'
            });
            this.snapshotAddress = undefined;
            this.oldRadioAddressIndex = -1;
          }
          break;
        }
        case 'cartStore/UPDATE_CART_ITEM_OK': {
          if (this.increasedItemIndex > -1) {
            this.cart.items[this.increasedItemIndex].quantity++;
            this.increasedItemIndex = -1;
          }
          if (this.decreasedItemIndex > -1) {
            this.cart.items[this.decreasedItemIndex].quantity--;
            this.decreasedItemIndex = -1;
          }
          if (this.removedItemIndex > -1) {
            this.cart.items.splice(this.removedItemIndex, 1);
            this.removedItemIndex = -1;
          }
          break;
        }
        case 'cartStore/UPDATE_CART_ITEM_FAILED': {
          let msg = '';
          if (this.increasedItemIndex > -1) {
            msg = 'Unable to increase cart item quantity now. Please try again later.';
            this.increasedItemIndex = -1;
          }
          if (this.decreasedItemIndex > -1) {
            msg = 'Unable to increase cart item quantity now. Please try again later.';
            this.decreasedItemIndex = -1;
          }
          if (this.removedItemIndex > -1) {
            msg = 'Unable to increase cart item quantity now. Please try again later.';
            this.removedItemIndex = -1;
          }
          this.$store.dispatch('uiStore/setSnackbar', {
            color: 'error',
            action: 'UPDATE_CART_ITEM_FAILED',
            text: msg
          });
          break;
        }
      }
    });
  },
  beforeDestroy() {
    if (unsubscribeMutation) unsubscribeMutation();
    if (unsubscribeAction) unsubscribeAction();
  },
  mounted() {
    if (this.$route.query.payment_intent && this.$route.query.redirect_status === 'succeeded') {
      alert('Payment success');
      this.$router.push({
        name: ROUTE_NAME.CART_COMPONENT
      });
    }
  },
  methods: {
    // methods
    findSessionItem(cartItem: CartItem): SessionItem | null {
      const idx = this.session?.items.findIndex((i: SessionItem) => i.id === cartItem.itemId);
      if (idx > -1) return this.session.items[idx];
      else return null;
    },
    findCartItem(cartItem: CartItem, cartItems: CartItem[]): { index: number, item: CartItem } | null {
      const idx = cartItems.findIndex((i) => i.itemId === cartItem.itemId);
      if (idx > -1) return { item: cartItems[idx], index: idx };
      else return null;
    },
    calcItemTotal(): string {
      let itemTotal = 0;
      if (this.cart && this.cart.items) {
        this.cart.items.forEach((i: CartItem) => {
          itemTotal += i.price * i.quantity;
        });
      }
      this.itemTotal = itemTotal;
      return itemTotal.toFixed(2);
    },
    calcShippingTotal(): string {
      return (+this.shippingTotal).toFixed(2);
    },
    calcTotal(): string {
      return (+this.itemTotal + +this.shippingTotal).toFixed(2);
    },
    constructTitle(cartItem: CartItem) {
      if (this.session?.items.length) {
        const found = this.findSessionItem(cartItem);
        if (found) {
          return `${found.code} - ${found.name}`;
        } else return '';
      } else {
        return '';
      }
    },
    calcUnitPrice(quantity = 1, cartItem: CartItem) {
      const found = this.findSessionItem(cartItem);
      if (found) {
        return (quantity * found.price).toFixed(2);
      } else {
        return 0;
      }
    },
    calcShippingPrice(code: string, price: string) {
      return `${code} - ${this.currency()} ${parseFloat(price).toFixed(2)}`;
    },
    buildAddr(item: any) {
      const address = `${item.address} ${item.postcode} ${item.city} ${item.state} ${item.country}`;
      // if (withName) {
        // return `${item.name} (${item.contactNo}) - ${address}`;
      // } else {
      return address;
      // }
    },
    currency() {
      return this.session?.currency ? this.session.currency.toUpperCase() : '';
    },
    // events
    onAddressSaved() {
      const cartAddress = localStorage.getItem('cart_address');
      this.addresses = JSON.parse(cartAddress || '[]');
    },
    onAddressRemove(index: number) {
      if (index === this.deliveryAddressModel) {
        alert('Can\'t remove an address in use!');
        return;
      }
      if (confirm('Are you sure you want to remove the selected address?')) {
        const cartAddress = localStorage.getItem('cart_address');
        const addrs = JSON.parse(cartAddress || '[]');
        if (addrs[index]) {
          addrs.splice(index, 1);
          localStorage.setItem('cart_address', JSON.stringify(addrs));
          this.onAddressSaved();
        }
      }
    },
    onAddressSelect(fullAddress: LocalStorageAddress) {
      // passing a cloned object to API and only update front end object when API update success
      this.snapshotAddress = JSON.parse(JSON.stringify(fullAddress));
      this.oldRadioAddressIndex = this.deliveryAddressModel;
      const deliverTo: DeliveryDetail = {
        name: this.snapshotAddress!.name,
        contactNo: this.snapshotAddress!.contactNo,
        address: {
          address: this.snapshotAddress!.address,
          postcode: this.snapshotAddress!.postcode,
          city: this.snapshotAddress!.city,
          state: this.snapshotAddress!.state,
          country: this.snapshotAddress!.country
        }
      };
      this.$store.dispatch('cartStore/updateCartAction', {
        cartId: this.cartId,
        shippingFeeId: this.shippingFeeId,
        delivery: deliverTo
      });
    },
    onShipMethodClick(item: ShippingFee) {
      // passing a cloned object to API and only update front end object when API update success
      this.oldRadioShippingFeeId = this.shippingMethodModel;
      this.snapshotItem = item;
      this.$store.dispatch('cartStore/updateCartAction', {
        cartId: this.cartId,
        shippingFeeId: item.id,
        delivery: this.delivery
      });
    },
    onCartItemRemove(cartItem: CartItem, cartItems: CartItem[]) {
      // passing a cloned object to API and only update front end object when API update success
      const found = this.findCartItem(cartItem, cartItems);
      if (found && found.item) {
        if (confirm('Are you sure you want to remove this item from the cart?')) {
          this.removedItemIndex = found.index;
          const items = JSON.parse(JSON.stringify(cartItems));
          items.splice(this.removedItemIndex, 1);
          this.$store.dispatch('cartStore/updateCartItemsAction', {
            items,
            cartId: this.cartId,
          });
        }
      }
    },
    onQuantityIncrease(cartItem: CartItem, cartItems: CartItem[]) {
      // passing a cloned object to API and only update front end object when API update success
      const found = this.findCartItem(cartItem, cartItems);
      if (found && found.item) {
        this.increasedItemIndex = found.index;
        const items = JSON.parse(JSON.stringify(cartItems));
        items[this.increasedItemIndex].quantity++;
        this.$store.dispatch('cartStore/updateCartItemsAction', {
          items,
          cartId: this.cartId,
        });
      }
    },
    onQuantityDecrease(cartItem: CartItem, cartItems: CartItem[]) {
      // passing a cloned object to API and only update front end object when API update success
      const found = this.findCartItem(cartItem, cartItems);
      if (found && found.item) {
        if (cartItems[found.index].quantity - 1 <= 0) {
          this.onCartItemRemove(cartItem, cartItems);
        } else {
          this.decreasedItemIndex = found.index;
          const items = JSON.parse(JSON.stringify(cartItems));
          items[this.decreasedItemIndex].quantity--;
          this.$store.dispatch('cartStore/updateCartItemsAction', {
            items,
            cartId: this.cartId,
          });
        }
      }
    },
    onCheckout() {
      if (!this.shippingMethodModel) {
        alert('Please select shipping method.');
        return;
      } else if (this.deliveryAddressModel < 0 || this.deliveryAddressModel > this.addresses.length - 1) {
        alert('Please select delivery address.');
        return;
      } else {
        this.payLoading = true;
        axios.post(`https://api.billerbee.com/v1/cart/${this.cartId}/checkout/`).then((res) => {
          if (res.data?.clientSecret) {
            this.$router.push({
              name: ROUTE_NAME.CART_PAYMENT,
              query: {
                // sa: res.data.stripeAccount,
                cs: res.data.clientSecret,
                cart: this.cartId
              }
            });
          } else if (res.data && !res.data.clientSecret) {
            throw new Error('Client secret is missing.');
          }
          // else if (res.data && !res.data.stripeAccount) {
          //   throw new Error('Stripe account is missing.');
          // }
        }).catch((err) => {
          console.error(err);
          alert('Unable to checkout at the moment, something is not right at our end. Please try again later.');
        }).finally(() => {
          this.payLoading = false;
        });
      }
    },
    onViewFbPost() {
      window.open(this.session.postURL, '_blank');
    }
  },
});
