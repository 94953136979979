import { ShippingTemplate } from '@/store/setting-store';
// import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// async function putShippingTemplate(body: ShippingTemplate, token: string): Promise<ShippingTemplate> {
//   try {
//     const response = await axios.put<ShippingTemplate>(`${process.env.VUE_APP_API_HOST}/shippingTemplate`, body, {
//       headers: { Authorization: token }
//     });
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

// async function getShippingTemplates(token: string): Promise<ShippingTemplate[]> {
//   try {
//     const response = await axios.get<ShippingTemplate[]>(`${process.env.VUE_APP_API_HOST}/shippingTemplate`, {
//       headers: { Authorization: token }
//     });
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

// async function getShippingTemplate(id: string, token: string): Promise<ShippingTemplate> {
//   try {
//     const response = await axios.get<ShippingTemplate>(`${process.env.VUE_APP_API_HOST}/shippingTemplate/${id}`, {
//       headers: { Authorization: token }
//     });
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

// async function patchShippingTemplate(body: ShippingTemplate, token: string): Promise<string> {
//   try {
//     const response = await axios.patch<string>(`${process.env.VUE_APP_API_HOST}/shippingTemplate/${body.id}`, body, {
//       headers: { Authorization: token }
//     });
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

// async function deleteShippingTemplate(id: string, token: string): Promise<string> {
//   try {
//     const response = await axios.delete<string>(`${process.env.VUE_APP_API_HOST}/shippingTemplate/${id}`, {
//       headers: { Authorization: token }
//     });
//     return response.data;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }

function putShippingTemplate(body: ShippingTemplate): ShippingTemplate {
  try {
    const shippingTemplates: ShippingTemplate[] = JSON.parse(localStorage.getItem('bb_st') || '[]');
    body.id = uuidv4();
    shippingTemplates.push(body);

    localStorage.setItem('bb_st', JSON.stringify(shippingTemplates));
    return body;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function getShippingTemplates(): ShippingTemplate[] {
  try {
    const shippingTemplates: ShippingTemplate[] = JSON.parse(localStorage.getItem('bb_st') || '[]');
    return shippingTemplates;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function getShippingTemplate(id: string): ShippingTemplate {
  try {
    const shippingTemplates: ShippingTemplate[] = JSON.parse(localStorage.getItem('bb_st') || '[]');
    const shippingTemplate = shippingTemplates.find((st) => st.id === id);

    return shippingTemplate!;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function patchShippingTemplate(body: ShippingTemplate): string {
  try {
    const shippingTemplates: ShippingTemplate[] = JSON.parse(localStorage.getItem('bb_st') || '[]');
    const idx = shippingTemplates.findIndex((st) => st.id === body.id);
    shippingTemplates[idx] = body;

    localStorage.setItem('bb_st', JSON.stringify(shippingTemplates));

    return body.id;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function deleteShippingTemplate(id: string): string {
  try {
    const shippingTemplates: ShippingTemplate[] = JSON.parse(localStorage.getItem('bb_st') || '[]');
    const idx = shippingTemplates.findIndex((st) => st.id === id);
    shippingTemplates.splice(idx, 1);

    localStorage.setItem('bb_st', JSON.stringify(shippingTemplates));

    return id;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export {
  getShippingTemplates,
  getShippingTemplate,
  patchShippingTemplate,
  putShippingTemplate,
  deleteShippingTemplate
};
