export const themes = {
  light: [
    {
      primary: '#C3CED5',
      secondary: '#FF9909',
      accent: '#D4DDE2',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#212121',
      background: '#EBEBEB'
    },
    {
      primary: '#E0DFCF',
      accent: '#F1EFE8',
      secondary: '#FFCA28',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#212121',
      background: '#EBEBEB'
    },
    {
      primary: '#E8DEDC',
      accent: '#F4EDEC',
      secondary: '#F25442',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#FFFFFF',
      background: '#EBEBEB'
    },
    {
      primary: '#C9CFD5',
      accent: '#E2E7EC',
      secondary: '#4293F5',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#FFFFFF',
      background: '#EBEBEB'
    },
    {
      primary: '#D3D9D3',
      accent: '#F0F4F0',
      secondary: '#A1CC65',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#212121',
      background: '#EBEBEB'
    },
    {
      primary: '#DFDCE2',
      accent: '#F6F4F8',
      secondary: '#915FD0',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#FFFFFF',
      background: '#EBEBEB'
    }
  ],
  dark: [
    {
      primary: '#344955',
      secondary: '#ff9500',
      accent: '#232f34',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#212121',
      background: '#292929'
    },
    {
      primary: '#53524A',
      accent: '#373632',
      secondary: '#FFCA28',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#212121',
      background: '#292929'
    },
    {
      primary: '#494443',
      accent: '#292524',
      secondary: '#F25442',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#FFFFFF',
      background: '#292929'
    },
    {
      primary: '#444F5A',
      accent: '#293038',
      secondary: '#4293F5',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#FFFFFF',
      background: '#292929'
    },
    {
      primary: '#434A43',
      accent: '#2B2F2B',
      secondary: '#A1CC65',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#212121',
      background: '#292929'
    },
    {
      primary: '#49454E',
      accent: '#2C2A2F',
      secondary: '#915FD0',
      success: '#007E33',
      info: '#0099CC',
      warning: '#FF8800',
      error: '#CC0000',
      contrast: '#FFFFFF',
      background: '#292929'
    }
  ]
};
