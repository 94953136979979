import { getOrders, OrderFilters, updateDelivery, UpdateDeliveryParams } from '@/services/order-service';
import { getSession } from '@/services/session-service';
// import { getSession } from '@/services/session-service';
// import { getShippingTemplate } from '@/services/setting-service';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import { DeliveryDetail } from './cart-store';
import { Session, ShippingFee } from './session-store';

const namespaced = true;
const defaultOrderState = (): OrderState => {
  return {
    selectedSessionId: '',
    orders: [],
    totalOrders: 0,
    orderSession: undefined,
    listLoading: false,
    updatingCourier: {
      loading: false,
      cartId: ''
    }
  };
};
const state = defaultOrderState();

const getters: GetterTree<OrderState, RootState> = {
  orderStore: (state) => state,
  orders: (state) => state.orders,
  totalOrders: (state) => state.totalOrders,
  listLoading: (state) => state.listLoading,
  updatingCourier: (state) => state.updatingCourier,
  selectedSessionId: (state) => state.selectedSessionId,
  orderSession: (state) => state.orderSession,
};

const actions: ActionTree<OrderState, RootState> = {
  async getOrdersAction({ commit, dispatch, rootState }, params: OrderFilters) {
    commit('listLoading', true);

    try {
      if (!params.sorting || !params.sorting.column) {
        params.sorting = {
          column: 'paidAt',
          desc: true
        };
      }
      const orders = await getOrders(params, rootState.authStore.token);
      const session = await getSession(params.sessionId, rootState.authStore.token);

      if (orders && orders.data && session) {
        dispatch('GET_ORDERS_OK', { session, orders });
      } else {
        throw new Error('API failure: Unable to get orders or session.');
      }

    } catch (err) {
      console.error(err);
      dispatch('GET_ORDERS_FAILED');
    } finally {
      commit('listLoading', false);
    }
  },

  GET_ORDERS_OK({ commit }, params: { session: Session, orders: { data: Order[], total: number }}) {
    commit('setSession', params.session);

    params.orders.data.forEach((order) => {
      // map items from session
      const mappedItems: MappedItems[] = [];
      if (!order.items) return;
      order.items.forEach((item) => {
        const sessionItem = params.session.items.find((x) => x.id === item.itemId);
        if (!sessionItem) return;
        mappedItems.push({
          code: sessionItem.code,
          name: sessionItem.name,
          price: item.price,
          quantity: item.quantity
        });
      });
      order.mappedItems = mappedItems;

      // map full address
      if (order.deliverTo.address) {
        order.deliverTo.fullAddress = `${order.deliverTo.address.address.trim()} ${order.deliverTo.address.postcode?.toString().trim()} ${order.deliverTo.address.city.trim()} ${order.deliverTo.address.state.trim()} ${order.deliverTo.address.country.trim()}`;
      }
    });
    commit('setOrders', params.orders);
  },

  GET_ORDERS_FAILED({ commit }) {
    commit('setOrders', []);
  },

  async updateCourier({ commit, dispatch, rootState }, params: UpdateDeliveryParams) {
    commit('setUpdatingCourier', {
      cartId: params.cartId,
      loading: true
    });
    try {
      const isUpdated = await updateDelivery(params, rootState.authStore.token);
      if (isUpdated) {
        dispatch('UPDATE_COURIER_OK');
      } else {
        throw new Error('API failure: Unable to update courier and tracking number.');
      }
    } catch (err) {
      console.error(err);
      dispatch('UPDATE_COURIER_FAILED');
    } finally {
      commit('setUpdatingCourier', {
        cartId: '',
        loading: false
      });
    }
  },

  UPDATE_COURIER_OK() {
    // update courier and tracking success
  },

  UPDATE_COURIER_FAILED() {
    // update courier and tracking failed
  },

  selectSessionAction({ commit }, params: { sessionId: string }) {
    commit('setSelected', params.sessionId);
  },

  async resetOrderState({ commit }) {
    commit('resetOrderState');
  }
};

const mutations: MutationTree<OrderState> = {
  listLoading: (state, loading: boolean) => {
    state.listLoading = loading;
  },
  setUpdatingCourier: (state, updating: { cartId: string; loading: boolean }) => {
    state.updatingCourier.cartId = updating.cartId;
    state.updatingCourier.loading = updating.loading;
  },
  setOrders: (state, orders: { data: Order[], total: number }) => {
    state.orders = orders.data;
    state.totalOrders = orders.total;
  },
  setSession: (state, data: Session) => {
    state.orderSession = data;
  },
  setSelected: (state, sessionId: string) => {
    state.selectedSessionId = sessionId;
  },
  resetOrderState: (state) => {
    state.orders = defaultOrderState().orders;
    state.totalOrders = defaultOrderState().totalOrders;
    state.listLoading = defaultOrderState().listLoading;
    state.updatingCourier = defaultOrderState().updatingCourier;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export interface OrderState {
  selectedSessionId: string;
  orders: Order[];
  totalOrders: number;
  orderSession?: Session;
  listLoading: boolean;
  updatingCourier: {
    loading: boolean;
    cartId: string;
  };
}

export interface Order {
  id: string;
  sessionId: string;
  customerId: string;
  customerName: string;
  paid: boolean;
  paidAt: string;
  items?: OrderItem[];
  deliverTo: DeliveryDetail;
  deliveryTrackingNumber?: string;
  deliveryServiceProvider?: string;
  shippingFeeId: string;
  shippingFee?: ShippingFee;
  total: number;
  mappedItems: MappedItems[];
}

export interface OrderItem {
  itemId: string;
  price: number;
  quantity: number;
}

export interface MappedItems {
  code: string;
  name: string;
  price: number;
  quantity: number;
}
