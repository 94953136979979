import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import Vue from 'vue';

const namespaced = true;
const defaultUiState = (): UiState => {
  return {
    confirmDialog: {
      from: '',
      show: false,
      title: '',
      text: '',
      okClicked: false,
      cancelClicked: false,
      color: '',
      icon: ''
    },
    infoDialog: {
      from: '',
      show: false,
      title: '',
      text: '',
      color: '',
      icon: ''
    },
    snackbar: [],
    alerts: [],
    alertHistory: [],
};
};
const state = defaultUiState();

const getters: GetterTree<UiState, RootState> = {
  confirmDialog: (state) => state.confirmDialog,
  infoDialog: (state) => state.infoDialog,
  snackbar: (state) => state.snackbar,
  alerts: (state) => state.alerts,
  alertHistory: (state) => state.alertHistory
};

const actions: ActionTree<UiState, RootState> = {
  setConfirmDialog({ commit }, params: ConfirmDialogDetail): void {
    commit('updateConfirmDialog', {
      from: params.from,
      show: true,
      text: params.text,
      title: params.title,
      color: params.color,
      icon: params.icon,
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  confirmDialogOk({ commit }, params: ConfirmDialogDetail): void {
    commit('setConfirmDialogOk');
  },
  confirmDialogCancel({ commit }): void {
    commit('setConfirmDialogCancel');
  },
  resetConfirmDialog({ commit }): void {
    commit('resetConfirmDialog');
  },
  setSnackbar({ commit }, params: SnackbarSetting): void {
    commit('setSnackbar', params);
  },
  resetSnackbar({ commit }): void {
    commit('resetSnackbar');
  },
  setInfoDialogAction({ commit }, params: InfoDialogDetail): void {
    commit('setInfoDialog', params);
  },
  infoDialogCloseAction({ commit }): void {
    commit('setInfoDialogClose');
  },
  insertAlertAction({ commit }, params: AlertSetting): void {
    commit('pushAlert', params);
  },
  removeAlertAction({ commit }, params: { name: ALERT_NAME }) {
    commit('removeAlert', params.name);
  },
  clearAlertAction({ commit }) {
    commit('clearAlert');
  },
  clearAlertHistoryAction({ commit }) {
    commit('clearAlertHistory');
  }
};

const mutations: MutationTree<UiState> = {
  updateConfirmDialog: (state, confirm: ConfirmDialogSetting) => {
    state.confirmDialog.from = confirm.from;
    state.confirmDialog.show = confirm.show;
    state.confirmDialog.title = confirm.title;
    state.confirmDialog.text = confirm.text;
    state.confirmDialog.show = confirm.show;
    state.confirmDialog.color = confirm.color || 'info';
    state.confirmDialog.icon = confirm.icon || 'mdi-alert-circle-outline';
  },
  setConfirmDialogOk: (state) => {
    state.confirmDialog.okClicked = true;
    state.confirmDialog.show = false;
  },
  setConfirmDialogCancel: (state) => {
    state.confirmDialog.cancelClicked = true;
    state.confirmDialog.show = false;
  },
  resetConfirmDialog: (state) => {
    const { confirmDialog, infoDialog } = defaultUiState();
    state.confirmDialog = confirmDialog;
    state.infoDialog = infoDialog;
  },
  setSnackbar: (state, params) => {
    Vue.$toast.open({
      message: params.text,
      dismissible: true,
      type: params.color,
      duration: 6000,
    });
  },
  resetSnackbar: (state) => {
    const { snackbar } = defaultUiState();
    state.snackbar = snackbar;
  },
  setInfoDialog: (state, params: InfoDialogDetail) => {
    state.infoDialog.show = true;
    state.infoDialog.from = params.from;
    state.infoDialog.text = params.text;
    state.infoDialog.title = params.title;
    state.infoDialog.color = params.color || 'info';
    state.infoDialog.icon = params.icon || 'mdi-alert-circle-outline';
  },
  setInfoDialogClose: (state) => {
    state.infoDialog.show = false;
  },
  pushAlert: (state, params: AlertSetting) => {
    // there might be some show = false alerts inside the state.alerts
    // so first step here is to filter out those and only work with show = true alerts!
    state.alerts = state.alerts.filter((a) => a.show === true);
    state.alerts.push(params);
    if (state.alertHistory.indexOf(params.name) < 0) {
      state.alertHistory.push(params.name);
    }
  },
  removeAlert: (state, name: ALERT_NAME) => {
    if (state.alerts.some((x) => x.name === name && x.show)) {
      state.alerts.forEach((x) => {
        if (x.name === name && x.show) {
          x.show = false;
        }
      });
    }
  },
  clearAlert: (state) => {
    state.alerts = [];
  },
  clearAlertHistory: (state) => {
    state.alertHistory = [];
  }
};

export interface AlertSetting {
  name: ALERT_NAME;
  show: boolean;
  type: string;
  text: string;
  color: string;
  showSettings: string;
}

export interface InfoDialogSetting {
  from: string;
  show: boolean;
  title: string;
  text: string;
  color: string;
  icon: string;
}

export interface InfoDialogDetail {
  from: string;
  title: string;
  text: string;
  color: string;
  icon: string;
}

export interface ConfirmDialogSetting {
  from: string;
  show: boolean;
  title: string;
  text: string;
  okClicked: boolean;
  cancelClicked: boolean;
  color: string;
  icon: string;
}

export interface ConfirmDialogDetail {
  from: string;
  title: string;
  text: string;
  color: string;
  icon: string;
}

export interface UiState {
  confirmDialog: ConfirmDialogSetting;
  infoDialog: InfoDialogSetting;
  snackbar: SnackbarSetting[];
  alerts: AlertSetting[];
  alertHistory: string[];
}

export interface SnackbarSetting {
  show: boolean;
  text: string;
  color: 'success' | 'info' | 'warning' | 'error' | '';
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
};

export enum ALERT_NAME {
  STRIPE_NOT_LINKED = 'STRIPE_NOT_LINKED',
}
